interface ClassProps {
  children: React.ReactNode;
  className?: string;
}

const Container: React.FC<ClassProps> = ({ children, className = "" }) => {
  return (
    <div className={`max-w-7xl mx-auto xl:px-0 px-8 ${className}`}>
      {children}
    </div>
  );
};

export default Container;
