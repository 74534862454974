import React from "react";
import clsx from "clsx";

interface ParagraphProps {
  children: React.ReactNode;
  size?: "sm" | "md" | "lg" | "xl";
  weight?: "light" | "normal" | "medium" | "semibold" | "bold";
  color?: "primary" | "secondary" | "danger" | "success" | "warning" | "muted";
  align?: "left" | "center" | "right";
  className?: string;
  margin?: string;
}

const Paragraph: React.FC<ParagraphProps> = ({
  children,
  size = "md",
  weight = "normal",
  color = "muted",
  align = "left",
  className,
  margin = "mb-3",
}) => {
  const sizeClasses = {
    sm: "text-sm",
    md: "text-base",
    lg: "text-lg",
    xl: "text-xl",
  };

  const weightClasses = {
    light: "font-light",
    normal: "font-normal",
    medium: "font-medium",
    semibold: "font-semibold",
    bold: "font-bold",
  };

  const colorClasses = {
    primary: "text-blue-600",
    secondary: "text-gray-600",
    danger: "text-red-600",
    success: "text-green-600",
    warning: "text-yellow-600",
    muted: "text-gray-500",
  };

  const alignClasses = {
    left: "text-left",
    center: "text-center",
    right: "text-right",
  };

  return (
    <p
      className={clsx(
        sizeClasses[size],
        weightClasses[weight],
        colorClasses[color],
        alignClasses[align],
        className,
        margin
      )}
    >
      {children}
    </p>
  );
};

export default Paragraph;
