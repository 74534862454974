import React, { useState, useRef, useEffect } from "react";
import { cn } from "@/lib/utils";

type TextareaSize = "sm" | "md" | "lg";
type TextareaState = "default" | "error" | "success" | "disabled";

type TextareaProps = {
  label?: string;
  name?: string;
  placeholder?: string;
  size?: TextareaSize;
  state?: TextareaState;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  maxLength?: number;
  showCounter?: boolean;
  autoGrow?: boolean;
  resizable?: boolean;
  border?: string;
  rows?: number;
};

const sizeClasses: Record<TextareaSize, string> = {
  sm: "px-2 py-1 text-sm",
  md: "px-3 py-2 text-base",
  lg: "px-4 py-3 text-lg",
};

const stateClasses: Record<TextareaState, string> = {
  default: "border-gray-300 focus:ring-blue-500",
  error: "border-red-500 focus:ring-red-500",
  success: "border-green-500 focus:ring-green-500",
  disabled: "border-gray-300 bg-gray-100 cursor-not-allowed text-gray-500",
};

const Textarea: React.FC<TextareaProps> = ({
  label,
  name,
  rows = 4,
  placeholder = "Enter Text Here",
  size = "md",
  state = "default",
  value = "",
  onChange,
  disabled = false,
  required,
  className = "",
  maxLength,
  showCounter = false,
  autoGrow = false,
  resizable = true,
  border = "border border-gray-300",
}) => {
  const [text, setText] = useState(value);
  const textRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (autoGrow && textRef.current) {
      textRef.current.style.height = "auto";
      textRef.current.style.height = `${textRef.current.scrollHeight}px`;
    }
  }, [text, autoGrow]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    if (onChange) onChange(e);
  };

  return (
    <div className="flex flex-col space-y-1 w-full">
      {label && <label className="text-sm font-medium">{label}</label>}
      <div className={cn("relative", className)}>
        <textarea
          ref={textRef}
          name={name}
          placeholder={placeholder}
          value={text}
          onChange={handleChange}
          disabled={disabled}
          required={required}
          maxLength={maxLength}
          rows={rows}
          className={cn(
            "w-full rounded-md outline-none transition-all focus:ring-2",
            sizeClasses[size],
            stateClasses[state],
            border,
            resizable ? "resize" : "resize-none"
          )}
        />
        {showCounter && maxLength && (
          <div className="text-right text-xs text-gray-500 mt-1">
            {text.length}/{maxLength}
          </div>
        )}
      </div>
    </div>
  );
};

export default Textarea;
