import React, { ReactNode } from "react";

interface ClassProps {
  children: ReactNode;
}

const PreHeading: React.FC<ClassProps> = ({ children }) => {
  return (
    <div className="text-lg text-black/60 uppercase font-semibold">
      {children}
    </div>
  );
};

export default PreHeading;
