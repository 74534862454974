import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavItems from "./NavItems";
import SideNav from "./sideNav/sideNav";
import GetStartedButton from "../ctaButtons/getStartedButton";
import Container from "@/components/ui/Container";

import ProductsDropdown from "./subNavComponents/productsDropdown/productsDropdown";
import StoriesDropdown from "./subNavComponents/storiesDropdown/storiesDropdown";
import AgentsDropdown from "./subNavComponents/agentsDropdown/agentsDropdown";
import CompanyDropdown from "./subNavComponents/companyDropdown/companyDropdown";
import LearnDropdown from "./subNavComponents/learnDropdown/learnDropdown";

// Define your nav links including dropdown content (if any)
const links = [
  { id: "Home", to: "/", title: "Home" },
  { id: "Product", title: "Platform", dropdown: <ProductsDropdown /> },
  /*{
    id: "ai_agents",
    title: "Agents",
    to: "/agents",
    dropdown: <AgentsDropdown />,
  },*/
  {
    id: "stories",
    title: "Stories",
    to: "/stories",
    dropdown: <StoriesDropdown />,
  },
  { id: "Learn", title: "Learn", dropdown: <LearnDropdown /> },
  { id: "Company", title: "Company", dropdown: <CompanyDropdown /> },
];

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  // activeDropdown holds the id of the nav item whose dropdown is open (or null if none)
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Change navbar styling on scroll
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // When the route changes, close any open dropdowns and the mobile menu.
  useEffect(() => {
    setActiveDropdown(null);
    setIsSideNavOpen(false);
    document.body.classList.remove("overflow-hidden");
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // Toggle dropdown state. If the same nav item is clicked twice, close it.
  const handleNavItemClick = (id) => {
    setActiveDropdown((prevId) => (prevId === id ? null : id));
  };

  // Toggle the mobile side nav and adjust the body’s overflow.
  const toggleSideNav = () => {
    setIsSideNavOpen((prev) => {
      const newState = !prev;
      if (newState) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
      return newState;
    });
  };

  return (
    <header
      className={`navbar fixed w-full z-40 border-b border-gray-200  ${
        isScrolled ? "bg-white/10 backdrop-blur-lg  " : "bg-transparent"
      }`}
    >
      <Container className="flex justify-between items-center py-3 relative">
        <Link to="/" className="navbar-logo">
          <img src="/images/logos/logo_dark.svg" alt="Cynepia" width={130} />
        </Link>
        {/* Desktop navigation */}
        <nav className="hidden lg:block">
          <NavItems
            links={links}
            activeDropdown={activeDropdown}
            onNavItemClick={handleNavItemClick}
            navigate={navigate}
          />
        </nav>
        <div className="hidden lg:block">
          <GetStartedButton
            className={isScrolled ? "button primary" : "basic"}
          />
        </div>
        {/* Mobile Hamburger */}
        <button
          className="lg:hidden p-2"
          aria-label="Toggle mobile menu"
          onClick={toggleSideNav}
        >
          {isSideNavOpen ? (
            <span className="text-xl">✖</span>
          ) : (
            <span className="text-xl">☰</span>
          )}
        </button>
      </Container>
      {/* Mobile side nav */}
      {isSideNavOpen && (
        <SideNav
          closeSideNav={() => {
            setIsSideNavOpen(false);
            document.body.classList.remove("overflow-hidden");
          }}
          navigate={navigate}
          links={links}
        />
      )}
    </header>
  );
};

export default Navbar;
