import React, { Component } from "react";
import { Link } from "react-router-dom";
import AnimatedArrowIconLink from "../animatedArrowIconLink/animatedArrowIconLink";
import HeadingComponent from "@/components/ui/HeadingComponent";

const CardItem = ({ data, readMoreEnabled }) => {
  const { thumbnail_img, title, shortDescription, displayUrl, displayTags } =
    data;
  const link = `/story/${displayUrl}`;
  return (
    <div className="flex border border-gray-300 rounded-lg  bg-white">
      <div className="flex flex-col w-full transition-transform hover:scale-101">
        <Link to={link}>
          <div
            className="w-full h-48 bg-cover bg-center cursor-pointer relative"
            style={{
              backgroundImage: `url( ${thumbnail_img} )`,
            }}
          ></div>
        </Link>

        <div className="p-6">
          <div className="flex items-center">
            <div>
              <Link to={link}>
                <HeadingComponent className="text-xl font-semibold mb-3 cursor-pointer hover:text-blue-600 transition-colors">
                  {" "}
                  {title}
                </HeadingComponent>
              </Link>
            </div>
          </div>
          <div className="flex items-center flex-wrap mb-3 gap-2">
            {displayTags &&
              displayTags.map((tag, index) => (
                <span
                  key={index}
                  className="rounded-full bg-blue-100 text-blue-500 px-2 py-1 font-medium text-xs inline-flex"
                >
                  {tag}
                </span>
              ))}
          </div>
          <p className="text-gray-600 mb-4">{shortDescription}</p>
          {readMoreEnabled ? (
            <AnimatedArrowIconLink to={link} state content={"Read More"} />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default CardItem;
