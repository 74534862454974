import GetStartedButton from "@/components/ctaButtons/getStartedButton";
import ContactUsButton from "./ContactUsButton";

interface CtaButtonsProps {
  center?: boolean;
  centerClass?: string;
  contactBtnHidden?: boolean;
  size?: string; // Add size if you intend to use it
}

export default function CtaButtons(props: CtaButtonsProps) {
  const classNames = ` 
    flex items-center gap-3 ${
      props.center ? "justify-center " : "justify-start"
    }
    ${props.centerClass}
  `;

  return (
    <div className="cta-wrapper mb-5">
      <div className={classNames}>
        <GetStartedButton />
        {props.contactBtnHidden !== true && <ContactUsButton size={props.size} />}
      </div>
    </div>
  );
}
