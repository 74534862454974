import { Link } from "react-router-dom";
import { cn } from "@/lib/utils.tsx";

const GetStartedButton = ({ classNames }: { classNames?: string }) => {
  return (
    <Link to="/get-started">
      <button
        className={cn(
          "cursor-pointer py-2 px-4 inline-flex justify-center items-center gap-x-2 text-md font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none",
          classNames || ""
        )}
      >
        Get started
        <svg
          className="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="m9 18 6-6-6-6" />
        </svg>
      </button>
    </Link>
  );
};

export default GetStartedButton;
