import React from "react";
import CynepiaClientsQuotations from "@/components/cynepiaClientsQuotations/cynepiaClientsQuotations";
import GetStartedSection from "@/components/getStartedSection/getStartedSection";
import ListComponent from "@/components/listComponent/listComponent";
import CtaButtons from "@/components/ctaButtons/ctaButtons";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import PreHeading from "@/components/ui/PreHeading";
import HeroComponent from "@/components/ui/HeroComponent";
import HeadingComponent from "@/components/ui/HeadingComponent";

const AboutUs: React.FC = () => {
  return (
    <div id="about-us-page">
      <PageSection>
        <Container>
          <HeroComponent
            title="Our Story"
            description=" Cynepia was founded on the belief that data is the single most
            important factor that determines the ability of businesses to
            compete, manage their customer relationships, and transform and
            transition to lead in the future."
            layout="column"
            ctaButtons={<CtaButtons center />}
            imageSrc="/images/glassBlockBgAbstract.jpg"
          />
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <PreHeading>The Problem</PreHeading>
              <HeadingComponent level={2} title="Current market challenge" />
              <p>
                With the adoption of a modern data stack, the architecture for
                DATA/ML has become more complex than ever before, and
                enterprises experience a variety of issues, including the
                complexity of integrating a multitude of bespoke tools, as well
                as the difficulties in keeping such bespoke tools running.
              </p>
              <p>
                Maintaining the modern data stack and keeping it running was
                never going to be easy, and with the shortage of data architects
                and engineers, enterprises are faced with a challenging task of
                keeping these bespoke tools running.
              </p>
              <p>
                In the midst of increasing consumption of data talent, many
                mid-sized companies (MM/SME) are further struggling to keep data
                flowing freely through multiple proprietary tools/systems and
                make them interoperable and frictionless.
              </p>
              <p>
                AT CYNEPIA, OUR MISSION is to enable enterprises address the
                complexity of the modern data stack by providing a modern, Data
                First, AI Platform which is governable, collaborative and
                frictionless, brings the data builders and data consumers
                togethers, thereby helping our customers turn data into value
                rapidly.
              </p>
            </div>
            <div>
              <img
                src="/images/aboutUs1.png"
                alt="Cynepia about us"
                className="w-full"
              />
            </div>
          </div>
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <div className="grid md:grid-cols-2 gap-6 items-start">
            <div>
              <img
                src="/images/aboutUs2.png"
                alt="Cynepia about us"
                className="w-full"
              />
            </div>
            <div>
              <PreHeading>The Solution</PreHeading>
              <HeadingComponent level={2} title="How are we doing it?" />
              <p>
                Our approach with End to End Data First AI Platform helps
                enterprises turn data into value rapidly. Xceed Intelligence
                Platform translates our vision and imagination of what a Modern
                data stack needs to deliver. We own the user experience and
                bring the benefits of modern data stack to the larger enterprise
                market including MM and SME segments.
              </p>
              <p>
                The company is expanding its geographical footprint and working
                to achieve its vision of becoming a global organization.
              </p>
              <p>
                In the midst of increasing consumption of data talent, many
                mid-sized companies (MM/SME) are further struggling to keep data
                flowing freely through multiple proprietary tools/systems and
                make them interoperable and frictionless.
              </p>
              <p>
                AT CYNEPIA, OUR MISSION is to enable enterprises address the
                complexity of the modern data stack by providing a modern, Data
                First, AI Platform which is governable, collaborative and
                frictionless, brings the data builders and data consumers
                togethers, thereby helping our customers turn data into value
                rapidly.
              </p>
            </div>
          </div>
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={3}>
            Benefits of our approach to our customers
          </HeadingComponent>
          <ListComponent
            data={[
              "Enterprises can now focus their energy on the data use cases and not on modern data stack architecture and complexities of bringing bespoke tools together.",
              "We are single mindedly focused on reducing the friction and high cost of ownership of modern data architecture and eliminating the bottlenecks of sub-optimal architecture.",
              "Help both data builders and data consumers collaborate and govern data together in a friction-less way.",
              "Help enterprises govern and manage there data assets seamlessly.",
            ]}
          ></ListComponent>
        </Container>
      </PageSection>

      <CynepiaClientsQuotations />

      <PageSection>
        <Container>
          <HeadingComponent level={3} title="Where are we headed?" />
          <p>
            We are growing our geographical footprint to be closer to our
            customers and be a leading player in the Data First AI Platform
            space.
          </p>
          <p>
            Our promise of the past to bring a future-ready and future-proof
            Data First AI Platform continues in the future as well. The twin
            foundation of best-in-class architecture and best-in-class user
            experience is what we continue to focus on from engineering
            execution wise. We are investing even more aggressively by further
            in attracting top talent who are passionate about solving the same.
          </p>
        </Container>
      </PageSection>

      <GetStartedSection />
    </div>
  );
};

export default AboutUs;
