import React from "react";
import ListComponent from "@/components/listComponent/listComponent";
import PageSectionContent from "@/components/ui/PageSectionContent";

const CustomAgentsSection = () => {
  return (
    <>
      <PageSectionContent
        preHeading="Pre-built Agents"
        title="Data Agents for various roles and tasks"
        contentType="list"
        listItems={[
          "Unified approach accelarates building agents for all data roles",
          "Xceed Intelligence framework enables building, augmenting and customizing data agents for various tasks/processes",
          "Jane, the business analyst agent provides conversational experience for getting answers and building dashboards",
          "Coming soon, Liam the Data Engineer Agent and Mary, A Data Science Agent...",
        ]}
        imageSrc="/images/dataAgentList.svg"
        imageAlt="Data agents for all roles"
      />
      <PageSectionContent
        title="Your Data Analyst Agent"
        contentType="list"
        listItems={[
          "Conversational experience pre-integrated with Xceed Dashboards ensures best of AI/BI functionality",
          "Semantic index is automatically refreshed on a catalog/metadata udpate",
          "In-build vector database and search",
          "Data teams can influence agent performance and reliability by improving their knowledge base",
        ]}
        preHeading="Introducing Jane"
        imageSrc="/images/agentConversationPinup.svg"
        imageAlt="asdf"
        reverse={true}
      ></PageSectionContent>
      <PageSectionContent
        preHeading="Monitor Agent Performance in Real Time"
        title="Monitor and Take Action"
        contentType="list"
        listItems={[
          `Track overall performance of agents across threads/sessions`,
          `Track consumption by users, task or sessions`,
          "Drill down into specific failures and review the conversation",
          "Take actions by influencing trusted examples to correct agents behavior on specific issue",
        ]}
        imageSrc="/images/monitoring_details.svg"
        imageAlt="Monitoring details"
      ></PageSectionContent>
    </>
  );
};

export default CustomAgentsSection;
