import { useRef, useState } from "react";
import Button from "@/components/ui/Button";
import InputDateTime from "@/components/ui/InputDatetime";
import InputDropdown from "@/components/ui/InputDropdown";
import InputNumber from "@/components/ui/InputNumber";
import InputText from "@/components/ui/InputText";
import Textarea from "@/components/ui/TextArea";
import HeadingComponent from "@/components/ui/HeadingComponent";
import { getCurrentDateTime } from "@/lib/utils";
import toast from "react-hot-toast";
import timezones from "timezones.json";
// @ts-ignore
import { CountryList, DialCodeList } from "@/utilityData/utilityData";

interface FormData {
  firstname: string;
  lastname: string;
  dialcode: string;
  phone: string;
  lead_source: string;
  jobtitle: string;
  country: string;
  zip: string;
  scheduledate: string;
  last_schedule_meeting_time_zone: string;
  email: string;
  partner_type: string;
  message?: string;
  sign_up_for_newsletter?: boolean;
};

const PartnershipForm = () => {
  const [requestOnGoing, setRequestOnGoing] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formRef.current) return;

    setRequestOnGoing(true);

    try {
      const formData = new FormData(formRef.current);
      const data: Record<string, any> = Object.fromEntries(formData.entries());

      const bodyParams: Partial<FormData> = {
        ...data,
        phone: data.dialcode + data.phone,
        lead_source: "Web-Partners-Request",
        sign_up_for_newsletter: formData.has("sign_up_for_newsletter"),
      };

      delete bodyParams.dialcode; // Remove redundant dial code field

      const response = await fetch(
        `${window.__CreateContact__}/${btoa(JSON.stringify(bodyParams))}`,
        { method: "GET" }
      );

      const result = await response.json();
      toast[result.status === 200 ? "success" : "error"](
        result.status === 200 ? "Submission Successful" : "Submission Failed"
      );

      if (result.status === 200) formRef.current.reset();
      toast.success("Form Submitted Successfully");
    } catch (error) {
      toast.error("Submission Failed");
      console.error(error);
    } finally {
      setRequestOnGoing(false);
    }
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit} className="space-y-4">
      <HeadingComponent title="Send us a message" level={4} className="mb-10" />

      <div className="grid grid-cols-2 gap-4">
        <InputText
          label="First Name"
          name="firstname"
          placeholder="First Name"
          required
        />
        <InputText
          label="Last Name"
          name="lastname"
          placeholder="Last Name"
          required
        />
      </div>

      <InputDropdown
        options={[
          "Channel Partners/Resellers",
          "OEMs",
          "Technology Partners",
        ].map((item) => ({ label: item, value: item }))}
        label="Partner Type"
        name="partner_type"
      />

      <InputText
        label="Email"
        name="email"
        placeholder="Company Email"
        required
      />

      <div className="flex flex-row gap-4">
        <InputDropdown
          label="Code"
          name="dialcode"
          placeholder="00"
          options={DialCodeList.map((code: string) => ({
            label: code,
            value: code,
          }))}
        />
        <InputNumber
          label="Phone"
          name="phone"
          placeholder="Phone"
          required
          showSlider={false}
        />
      </div>

      <div className="flex gap-5 w-full">
        <div className="w-2/3">
          <InputDropdown
            label="Country"
            name="country"
            options={CountryList.map((name: string) => ({
              label: name,
              value: name,
            }))}
          />
        </div>
        <div className="w-1/3">
          <InputNumber
            label="Zip Code"
            name="zip"
            placeholder="Zip Code"
            required
          />
        </div>
      </div>

      <div className="flex items-center gap-4">
        <InputDateTime
          name="scheduledate"
          label= "Schedule Meeting"
          showTime
          minDate={getCurrentDateTime()}
          required
        />
        <InputDropdown
          label="Enter Timezone"
          name="last_schedule_meeting_time_zone"
          options={timezones.map((tz: { text: string }) => ({
            label: tz.text,
            value: tz.text,
          }))}
        />
      </div>

      <Textarea
        label="Message (optional)"
        name="message"
        placeholder="Your Message"
        rows={4}
      />

      <div>
        <input
          type="checkbox"
          defaultChecked
          id="sign_up_for_newsletter"
          name="sign_up_for_newsletter"
          className="mr-2"
        />
        <label htmlFor="sign_up_for_newsletter">
          Sign me up for newsletter
        </label>
      </div>

      <Button disabled={requestOnGoing} className="w-full">
        {requestOnGoing ? "Submitting..." : "Contact Us"}
      </Button>
    </form>
  );
};

export default PartnershipForm;
