import React, { Component } from "react";
import IndustrySpecific from "./industrySpecific/industrySpecific";
import RoleSpecific from "./roleSpecific/roleSpecific";
import { LinkItemList } from "../../LinkListItems/LinkListItems";
import {
  Banknote,
  ChartScatter,
  FlaskConical,
  HandCoins,
  ShoppingCart,
  UserCog,
} from "lucide-react";

const IndustryItems = [
  {
    title: "Financial Services",
    description:
      "Combine data from all of your banking applications. Solve growth vs risk conundrum, create customer 360 personas to stregthen customer relations",
    to: "/stories?industry=financial-services",
    iconComponent: <HandCoins size={20} />,
  },
  {
    title: "Retail & CPG",
    description:
      "Drive growth, streamline operations, cross-sell/up-sell while creating unique shopper experiences.",
    to: "/stories?industry=retail-services",
    iconComponent: <ShoppingCart size={20} />,
  },
];

const RoleSpecificItems = [
  {
    title: "Data Analyst",
    description:
      "Access information about the data you need from Data Catalog, Instantly Create Meaningful stories and share with Business Users.",
    to: "/stories?role=data-analyst",
    iconComponent: <ChartScatter size={20} />,
  },
  {
    title: "Data Scientist",
    description:
      "Discover Data/Features from Data Catalogue, Create Expermiments using code or AutoML in hours,  Build and Publish/Deploy Model in days.",
    to: "/stories?role=data-scientist",
    iconComponent: <FlaskConical size={20} />,
  },
  {
    title: "Data Engineers",
    description:
      "Combine data from different databases/applications, Prepare/Transform data using no-code ui, quickly create golden datasets and share with users who need the same.",
    to: "/stories?role=data-engineer",
    iconComponent: <UserCog size={20} />,
  },
  {
    title: "Business User",
    description:
      "Be on top of your business KPIs, Access Dashboards/Stories. Get notified about newly published dashboards/stories.",
    to: "/stories?role=business-user",
    iconComponent: <Banknote size={20} />,
  },
];

export default class StoriesDropdown extends Component {
  render() {
    return (
      <div className="nav-dropdown" id="storiesDropdown">
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-4">
            <LinkItemList title="BY INDUSTRY" items={IndustryItems} />
          </div>
          <div className="col-span-8">
            <LinkItemList title="BY ROLE" items={RoleSpecificItems} cols={2} />
          </div>
        </div>
      </div>
    );
  }
}
