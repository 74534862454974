import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { Toaster } from "react-hot-toast";

import Home from "@/components/pages/home/home";
import XceedArchitecture from "@/components/pages/products/architecture/Architecture";
import SelfServiceBI from "@/components/pages/products/selfServiceBI/selfServiceBI";
import Files from "@/components/pages/products/files/files";
import Pricing from "@/components/pages/pricing/pricing";
import Partners from "@/components/pages/partners/partners";
import Privacy from "@/components/pages/legalPages/Privacy";
import ContactUs from "@/components/pages/contactUs/ContactUs";
import TermsOfUse from "@/components/pages/legalPages/TermsOfUse";
import GetInTouch from "@/components/pages/getInTouch/GetInTouch";
import AboutUs from "@/components/pages/aboutUs/aboutUs";
import Navbar from "@/components/Navbar/Navbar";
import Footer from "@/components/footer/footer";
import Stories from "@/components/pages/stories/stories";
import Story from "@/components/pages/story/story";
// import Agents from "@/components/pages/agents/agents";
// import Agent from "@/components/pages/agent/agent";
import DataConnector from "@/components/pages/products/dataConnector/dataConnector";
import DataCatalogue from "@/components/pages/products/dataCatalogue/dataCatalogue";
import ModelCatalog from "@/components/pages/products/modelCatalog/modelCatalog";
import DataEngineeringAndMl from "@/components/pages/products/dataEngineeringAndMl/dataEngineeringAndMl";
import SQLEditorView from "@/components/pages/products/sqlEditor/sqlEditorView";
import SmartSearch from "@/components/pages/products/smartSearch/smartSearch";
import AiAgentView from "@/components/pages/products/aiAgents/AiAgentView";
import DataEngineer from "@/components/pages/role/dataEngineer/dataEngineer";
import DataScientist from "@/components/pages/role/dataScientist/dataScientist";
import DataAnalyst from "@/components/pages/role/dataAnalyst/dataAnalyst";
import Page404 from "@/components/pages/page404/page404";
import ScrollToTop from "@/components/scrollToTop/scrollToTop";
import "./globalVars";

//          <Route path="/agents" element={<Agents />} />
  //        <Route path="/agents/:title" element={<Agent />} />
function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/products/architecture"
            element={<XceedArchitecture />}
          />
          <Route path="/products/data-connectors" element={<DataConnector />} />
          <Route
            path="/products/data-preparation"
            element={<DataEngineeringAndMl />}
          />
          <Route path="/products/data-catalog" element={<DataCatalogue />} />
          <Route path="/products/model-catalog" element={<ModelCatalog />} />
          <Route path="/role/data-engineer" element={<DataEngineer />} />
          <Route path="/role/data-scientist" element={<DataScientist />} />
          <Route path="/role/data-analyst" element={<DataAnalyst />} />
          <Route path="/products/self-service-bi" element={<SelfServiceBI />} />
          <Route path="/products/files" element={<Files />} />
          <Route
            path="/products/smart-ai-discovery-and-search"
            element={<SmartSearch />}
          />
          <Route
            path="/products/xceed-analytics-sql-Workbench"
            element={<SQLEditorView />}
          />
          <Route path="/products/ai-agents" element={<AiAgentView />} />
          <Route path="/products/pricing" element={<Pricing />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<TermsOfUse />} />
          <Route path="/stories" element={<Stories />} />
          <Route path="/story/:title" element={<Story />} />
          <Route path="/get-in-touch" element={<GetInTouch />} />
          <Route path="/get-started" element={<GetInTouch />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route
            path="/blogs"
            element={<Navigate to={window.__BlogUrl__} replace />}
          />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <Footer />
      </Router>
      <Toaster />
    </>
  );
}

export default App;
