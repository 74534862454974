import React, { Component } from "react";
import CardItem from "./cardItem";
import { cn } from "@/lib/utils";

export default class CardList extends Component {
  constructor(props) {
    super(props);
    this.settings = CardList.mergeDefaults(props);
  }

  static mergeDefaults(props) {
    const defaults = {
      gridLg: 3,
      gridMd: 2,
      gridSm: 1,
      data: [],
      readMoreEnabled: true,
      listItemClickCb: null,
    };
    const mergedDefaults = { ...defaults, ...props };
    mergedDefaults.gridClassNames = CardList.getGridClassNames(mergedDefaults);
    return mergedDefaults;
  }

  static getGridClassNames(props) {
    const { gridLg, gridMd, gridSm } = { ...props };
    // Tailwind grid columns map (12 column system)
    const gridMap = {
      1: "grid-cols-1",
      2: "grid-cols-2",
      3: "grid-cols-3",
      4: "grid-cols-4",
      6: "grid-cols-6",
      12: "grid-cols-12",
    };

    debugger;
    // Convert Bootstrap columns to Tailwind grid classes
    const lgCols = gridMap[gridLg] || "grid-cols-3";
    const mdCols = gridMap[gridMd] || "grid-cols-2";
    const smCols = gridMap[gridSm] || "grid-cols-1";

    return `grid sm:${smCols} md:${mdCols} lg:${lgCols}`;
  }

  render() {
    return (
      <div
        className={cn(
          this.settings.gridClassNames + " ",
          "flex flex-wrap gap-3"
        )}
      >
        {this.settings.data.map((data, i) => (
          <CardItem
            key={i}
            data={data}
            readMoreEnabled={this.settings.readMoreEnabled}
          />
        ))}
      </div>
    );
  }
}
