import React, { useState } from "react";

import PageSection from "../../../ui/PageSection";
import Container from "../../../ui/Container";
import Button from "../../../ui/Button";
import {
  FileChartPie,
  FlaskConical,
  LucideProps,
  Plus,
  Rocket,
} from "lucide-react";
import HeadingComponent from "@/components/ui/HeadingComponent";
import PreHeading from "@/components/ui/PreHeading";
import AIFeatureSectionComponent from "./AiFeatureSectionComponent";

export interface SectionType {
  id: string;
  title: string;
  icon: React.ForwardRefExoticComponent<
    Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>
  >;
  sectionDetails: any;
}

function AiFeatureSection() {
  const sections = [
    {
      id: "Create",
      title: "Create",
      sectionDetails: {
        title: "Create your agent",
        description:
          "Create purpose-built AI agents effortlessly using Xceed Agent builder or using Xceed Intelligence SDK.",
        ctaLabel: "Explore AI Create",
        ctaLink: "/products/ai-agents",
        leftImage: "/images/agentCreateSample.svg",
        rightImage: "/images/agentSampleListing.svg",
        leftBg: "/images/polkadot.svg",
        rightBg: "/images/gridbg.svg",
        leftWidth: "w-[350px]",
        rightWidth: "w-[250px]",
        showCTA: true,
      },

      icon: Plus,
    },
    {
      id: "Test",
      title: "Evaluate",
      sectionDetails: {
        title: "Evaluate Agent",
        description:
          "Conduct extensive evaluation with experiments, before deploying agents.  A library of pre-built evaluators and support for custom evaluators across LLM-as-a-judge and statistical. Test and iterate across prompts, models, tools, and context without code changes",
        ctaLabel: "Explore AI Evaluate",
        ctaLink: "/products/ai-agents",
        rightImage: "/images/evaulateHomePageImage.svg",
        leftBg: "",
        rightBg: "/images/gridbg.svg",
        rightWidth: "w-[600px]",
        containerClass: "border-s border-b border-gray-200",
        showCTA: true,
      },
      icon: FlaskConical,
    },
    {
      id: "Deploy",
      title: "Deploy",
      sectionDetails: {
        title: "Deploy Agent",
        description:
          "Simplify the deployment process by allowing users to instantly launch the agent in a production or testing environment with a single action. This feature automates configuration, infrastructure setup, and service integration, ensuring a seamless and efficient deployment experience.",
        ctaLabel: "Explore AI Deploy",
        ctaLink: "/products/ai-agents",
        leftImage: "",
        rightImage: "/images/DeployAgentForm.svg",
        leftBg: "",
        rightBg: "/images/gridbg.svg",
        leftWidth: "w-0",
        rightWidth: "w-[400px]",
        containerClass: "border-s border-b border-gray-200",
        showCTA: true,
      },

      icon: Rocket,
    },
    {
      id: "Monitor",
      title: "Monitor",
      sectionDetails: {
        title: "Observability & Continuous Monitoring",
        description:
          "Monitor your agents in real-time through a single pane and iterate and optimise performance. Use Readily available quick metrics and drill through into details.",
        ctaLabel: "Observe & Monitor",
        ctaLink: "/products/ai-agents",
        leftImage: "",
        rightImage: "/images/monitoringImage.svg",
        leftBg: "",
        rightBg: "/images/gridbg.svg",
        leftWidth: "w-0",
        rightWidth: "w-full",
        containerClass: "border-s border-gray-200",
        showCTA: true,
      },

      icon: FileChartPie,
    },
  ];

  const [selected, setSelected] = useState<SectionType>(sections[0]);

  const onTabClick = (section: SectionType) => {
    setSelected(section);
  };

  return (
    <Container className="border-s border-e border-gray-200">
      <PageSection classNames="lg:px-10 px-7">
        <PreHeading>Create, Test and Deploy Agents at Scale</PreHeading>
        <HeadingComponent level={2} className="mb-10">
          Agent Platform <br />
          for all enterprise needs
        </HeadingComponent>

        <div className="flex items-center justify-start gap-3 mb-7">
          {sections.map((section) => (
            <Button
              key={section.id}
              size="sm"
              variant={selected.title == section.title ? "solid" : "outline"}
              leftIcon={section.icon}
              onClick={() => onTabClick(section)}
            >
              {section.title}
            </Button>
          ))}
        </div>
        <AIFeatureSectionComponent {...selected.sectionDetails} />
      </PageSection>
    </Container>
  );
}

export default AiFeatureSection;
