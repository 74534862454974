import React, { useState, useEffect } from "react";
import CtaButtons from "../../ctaButtons/ctaButtons";
import { Sparkles } from "lucide-react";
import Container from "@/components/ui/Container.tsx";

const HeroSection = () => {
  const [heroImageVisible, setHeroImageVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setHeroImageVisible(true);
  }, []);

  //before:absolute before:top-0 before:start-1/2 before:bg-[url('https://preline.co/assets/svg/examples/polygon-bg-element.svg')] dark:before:bg-[url('https://preline.co/assets/svg/examples-dark/polygon-bg-element.svg')] before:bg-no-repeat before:bg-top before:bg-cover before:size-full before:-z-[1] before:transform before:-translate-x-1/2
  return (
    <div
      className="relative overflow-hidden border-b border-gray-200
      
    "
    >
      <Container className="border-s border-gray-200 border-e">
        <div className="mx-auto pt-35 pb-10">
          <div className="flex justify-center">
            <div className="shadow-sm bg-linear-to-t from-sky-500 to-indigo-500 rounded-full p-[2px] inline-flex items-center justify-center">
              <div className="rounded-full inline-flex items-center gap-x-2 bg-white text-sm text-gray-500 p-1 ps-5 transition hover:border-gray-300 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 dark:hover:border-neutral-600 dark:focus:border-neutral-600">
                PRO release - AI Agents
                <span className="py-1.5 px-2.5 inline-flex justify-center items-center gap-x-2 rounded-full bg-gray-200 font-semibold text-sm text-gray-600 dark:bg-neutral-700 dark:text-neutral-400">
                  <Sparkles size={20} className="text-indigo-500" />
                </span>
              </div>
            </div>
          </div>
          <div className="mt-5 text-center mx-auto">
            <h1 className="block font-semibold  text-gray-700 text-2xl md:text-5xl lg:text-6xl dark:text-neutral-200">
              Start deriving
              <br />
              <span className="text-primary">Limitless Value</span> with
              <br />
              <span className="text-primary">Xceed Intelligence Platform </span>
            </h1>
          </div>

          <div className="mt-6 max-w-2xl text-center mx-auto mb-8">
            <p className="text-lg text-gray-400 dark:text-neutral-400">
              Xceed Intelligence Platform empowers AI engineers and data
              teams to supercharge your enterprise productivity using data and
              AI agent applications.
            </p>
          </div>

          <CtaButtons center={true} />

          <div className="bg-gray-100/75 rounded-3xl borde w-full p-4">
            <img
              src="images/homePageHeroBanner.svg"
              alt="Cynepia"
              width="100%"
              className="rounded-2xl m-auto border-gray-200 shadow"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeroSection;
