import React from "react";
import { Check } from "lucide-react";

type ListComponentProps = {
  data: React.ReactNode[]; // Allows JSX elements
  icon?: React.ReactNode; // Allow custom icons
  className?: string; // Support custom styling
};

const ListComponent: React.FC<ListComponentProps> = ({
  data,
  icon = <Check size={16} />,
  className,
}) => {
  return (
    <div className={`flex flex-col items-start gap-3 ${className}`}>
      {data.map((item, index) => (
        <div className="flex items-start gap-2" key={index}>
          <span className="bg-blue-100 rounded-lg p-1 mt-0.5 flex items-center justify-center">
            {icon}
          </span>
          <span className="text-gray-700 dark:text-gray-300">{item}</span>
        </div>
      ))}
    </div>
  );
};

export default ListComponent;
