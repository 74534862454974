import ContactUsSection from "../../../contactUsSection/contactUsSection";
import ListComponent from "../../../listComponent/listComponent";
import Container from "@/components/ui/Container";
import PageSection from "@/components/ui/PageSection";
import HeroComponent from "@/components/ui/HeroComponent";
import HeadingComponent from "@/components/ui/HeadingComponent";
import Paragraph from "@/components/ui/Paragraph";

export default function Architecture() {
  return (
    <div className="page" id="architecturePage">
      <PageSection>
        <Container>
          <HeroComponent
            title="Architecture"
            imageSrc="/images/architectureBanner.svg"
            description=""
            layout="column"
          />
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={3}>Preface</HeadingComponent>
          <Paragraph>
            An intelligent data platform is the next evolution from the current
            warehouse/lake centric data platform environment. Along with the
            urge for simplification of the consumption interface, intelligent
            applications will drive the next decade of productivity. It
            addresses the challenges related to data silos, data
            quality/duplication and fragmentation of stack components. High
            quality, curated data and metadata is key to success with enterprise
            AI initiatives. An intelligent data platform, along with associated
            data applications, is poised to provide the foundation data and
            modeling layer infrastructure for AI use case enablement.
          </Paragraph>
          <Paragraph>
            While building grounds up is not a solution for 99 percent of the
            enterprises, Fragmented approach has brought in a variety of
            disadvantages beyond the complexity of integrating these disparate
            overlapping tools. It compromises user experience and user centric
            design. A classic analogy to this would be trying to bundle your own
            car vs buying different parts and putting it together. While it can
            deliver the functional car and the flexibility to choosing the
            pieces, the feel of the design from end user point of view is
            greatly compromised.
          </Paragraph>
          <Paragraph>
            <b>Xceed Intelligence Platform</b> takes an integrated approach to
            building AI agent applications. Along with the base platform
            capabilities, it provides a suite of intelligent control plane
            applications for managing and governing your data, metadata and AI
            models. Enterprises can now benefit from simplication of their data
            platform which not only helps them accelarate AI application, but
            also provides all the other product traits including pleasing user
            experience, architecture stiched together in a scalable, secure and
            governable platform.
          </Paragraph>
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={3}>
            User Centric, Scalable/Governable Intelligent Platform{" "}
          </HeadingComponent>
          <ListComponent
            data={[
              "Onboard, Clean/Prepare, Govern your data, Build & deploy models, Build Dashboards all through a single unified interface",
              `Micro/Macro-services architecture along with scaleable cloud based
              multi-engine orchestrator & choice of engines ensures horizontal
              and vertical scaling, while enabling variety of data and AI
              workloads.`,
              `Govern & Collaborate Centrally yet in a collaborative way.`,
              `No/Low Code Data Science Tools ensure ease of use for even
              non-technical users`,
            ]}
          ></ListComponent>
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={3}>
            Select Right Architecture for your enterprise data.
          </HeadingComponent>
          <ListComponent
            data={[
              "Xceed Intelligence Platform is an integrated Data & AI platform ensures all the AI agents & connected data & tools are availablethrough a common application interface. Onboard Data, Clean & Prepare Data, Govern, Build and deploy Models, Build AI Agents & Dashboards all through a unified interface.",
              "Deploy 100s of agents at scale for your enterprise.",
              "Micro/Macro-services architecture along with scaleable cloud based multi-engine orchestrator & choice of engines ensures horizontal and vertical scaling, while enabling variety of data and AI workloads.",
              "Govern & Collaborate centrally yet in a collaborative way.",
            ]}
          />
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={3}>
            Put right data in right hands
          </HeadingComponent>
          <ListComponent
            data={[
              "Centrally Govern and yet decentralize permissions to all your data and AI assets (agents, datasets, files, workflows, dashboards, ml models, deployments) all from a single interface.",
              "Create Users/Groups/Roles centrally and manage governance policies centrally.",
              "Agent Management System enable creating, testing and deploying AI agents at scale",
              "Centrailized Data Catalog with Data Profile ensures users can identify trusted & latest revisions of data.",
              "ML Catalog with 360 degree view of all model, artifacts, explainability, deployments. Playground enables testing and comparing models",
              "Secure sensitive information by enabling data permissions that govern access to specific data assets.",
              "Ensure row level access permissions right from data catalog, impacting all data access requests from downstream apps such as BI, export/download request for data.",
              "Enable Data Approval workflow for critical data assets ensuring only trusted data is made available to business users.",
            ]}
          />
        </Container>
      </PageSection>
      <ContactUsSection />
    </div>
  );
}
