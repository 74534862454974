import ListComponent from "@/components/listComponent/listComponent";
import Container from "@/components/ui/Container";
import HeadingComponent from "@/components/ui/HeadingComponent";
import PageSection from "@/components/ui/PageSection";
import Paragraph from "@/components/ui/Paragraph";
import PreHeading from "@/components/ui/PreHeading";
import ContactUsForm from "../contactUs/ContactUsForm";


const GetInTouch = () => {
  return (
    <PageSection>
      <Container>
        <div className="min-h-screen  py-12 px-6">
          <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-10">
            <div>
              <PreHeading>Get Started</PreHeading>
              <HeadingComponent level={2} className="text-3xl font-bold mb-4">
                Request Demo
              </HeadingComponent>
              <Paragraph>
                Get the Power of Xceed Intelligence Platform and drive your
                organization future.
              </Paragraph>
              <ListComponent
                data={[
                  "No coding required.",
                  "No credit required.",
                  "One tool for all data users in your enterprise.",
                ]}
              />
            </div>
            <div className="bg-white p-6 rounded-lg border border-gray-200 ">
              <ContactUsForm lead_source="Web-Getting-Started" />
            </div>
          </div>
        </div>
      </Container>
    </PageSection>
  );
};

export default GetInTouch;
