import React from "react";
import NavItem from "./NavItem";

const NavItems = ({ links, activeDropdown, onNavItemClick, navigate }) => {
  return (
    <ul className="flex  items-center">
      {links.map((link) => (
        <NavItem
          key={link.id}
          link={link}
          isActive={activeDropdown === link.id}
          onClick={() => onNavItemClick(link.id)}
          navigate={navigate}
        />
      ))}
    </ul>
  );
};

export default NavItems;
