import { Component } from "react";
import Container from "@/components/ui/Container";
import { Link } from "react-router-dom";
import Button from "@/components/ui/Button";

export default class GetStartedSection extends Component {
  render() {
    return (
      <div className="bg-blue-600">
        <div className="w-full bg-[url(/images/randomCirclesGradientBg.svg)] bg-center bg-no-repeat bg-cover h-[450px]">
          <Container className="h-full">
            <div className="flex justify-start items-center h-full">
              <div className="w-full flex flex-col gap-5">
                <div className="text-5xl font-semibold text-white/70">
                  <span className="mb-5">Get the power of </span>
                  <br />
                  <span className="text-white text-6xl">Futuristic Data </span>
                  <span className=" text-6xl"> & </span>{" "}
                  <span className="text-white text-6xl">AI Platform</span>{" "}
                  <br />
                  for your <span className="text-white">enterprise.</span>
                </div>
                <div className=" flex items-start justify-start">
                  <Link to="/get-started">
                    <Button size="lg" variant="solidBlueInverted">
                      Start For Free
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
