import { FC } from "react";
import PageSectionContent from "@/components/ui/PageSectionContent";

/*
interface ListItem {
  title: string;
  link: string;
  imgSrc: string;
}

const getList = (): ListItem[] => [
  {
    title: "Data Engineer",
    link: "/role/data-engineer",
    imgSrc: "/images/icons/dataEngineer.svg",
  },
  {
    title: "Data Analyst",
    link: "/role/data-analyst",
    imgSrc: "/images/icons/dataAnalyst.svg",
  },
  {
    title: "Data Scientist",
    link: "/role/data-scientist",
    imgSrc: "/images/icons/dataScientist.svg",
  },
  {
    title: "Business User",
    link: "/products/self-service-bi",
    imgSrc: "/images/icons/finance.svg",
  },
];
*/

const getListData = (): string[] => [
  "Accelerate the speed of innovation and use cases by empowering citizen data scientists and engineers to build powerful solutions.",
  "Easy-to-use modern visual editor lets even non-programmers transform data, build and deploy models.",
  "Do-it-yourself self-service interface enables business users/analysts to create and share visually appealing stories using a wide range of charts and visualizations.",
];

const DataDrivenCulture: FC = () => {
  return (
    <PageSectionContent
      title="Empower Citizen Data Engineers & Scientists to enable powerful insights."
      preHeading="Create Data Driven Culture"
      contentType="list"
      listItems={getListData()}
      imageSrc="/images/dataDrivenCulture.svg"
      imageAlt="Data Driven culture"
      bgClass=""
      gridClass=""
    />
  );
};

export default DataDrivenCulture;
