import React from "react";
import { Link } from "react-router-dom";
import SocialIcons from "../socialIcons/socialIcons";
import AnimatedArrowIconLink from "../animatedArrowIconLink/animatedArrowIconLink";
import SubscribeSection from "../subscribeSection/subscribeSection";
import Container from "@/components/ui/Container.tsx";
import HeadingComponent from "@/components/ui/HeadingComponent";

const Footer = () => {
  return (
    <div className="bg-gray-950 text-white" id="footer-section">
      <Container>
        <div className="py-3 border-b border-gray-700">
          <SubscribeSection />
        </div>
        <div className="container mx-auto py-12 px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7 gap-8">
            <div className="col-span-3">
              <div className="flex flex-col items-start justify-between flex-wrap mb-4 h-full">
                <img
                  src="/images/logos/logo_light.svg"
                  width="130"
                  alt="cynepia"
                />
                <SocialIcons bg="dark" />
              </div>
            </div>
            <div className="col-span-1">
              <FooterSection title="Platform" links={productLinks} />
            </div>
            <div className="col-span-1">
              <FooterSection title="Stories" links={storiesLinks} />
            </div>
            <div className="col-span-1">
              <FooterSection title="Resources" customLinks={resourcesLinks} />
            </div>
            <div className="col-span-1">
              <FooterSection title="Company" links={companyLinks} />
            </div>
          </div>
        </div>
        <div className="border-t border-gray-700 py-4 text-center text-sm">
          <div className="container mx-auto flex justify-between items-center">
            <small className="text-gray-400">
              @ Cynepia Technologies © 2022-2023
            </small>
            <div className="flex gap-4">
              <Link className="text-gray-400 hover:text-white" to="/privacy">
                Privacy Policy
              </Link>
              <Link className="text-gray-400 hover:text-white" to="/terms">
                Terms of Use
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

const FooterSection = ({ title, links, customLinks }) => (
  <div>
    <HeadingComponent
      level={6}
      className="font-semibold mb-3 text-white/30 text-sm"
    >
      {title}
    </HeadingComponent>
    <ul className="space-y-2">
      {links &&
        links.map((link, index) => (
          <li key={index}>
            <Link
              className="text-gray-400 hover:text-white text-sm"
              to={link.to}
            >
              {link.label}
            </Link>
          </li>
        ))}
      {customLinks &&
        customLinks.map((link, index) => (
          <li key={index}>
            <span
              className="cursor-pointer text-gray-400 hover:text-white"
              onClick={() => window.open(link.url, "_blank")}
            >
              <AnimatedArrowIconLink content={link.label} />
            </span>
          </li>
        ))}
    </ul>
  </div>
);

const productLinks = [
  { to: "/products/ai-agents", label: "AI Agents" },
  { to: "/products/self-service-bi", label: "Self Service BI" },
  { to: "/products/data-catalog", label: "Data Catalog" },
  { to: "/products/model-catalog", label: "Model Catalog" },
  { to: "/products/ai-agents", label: "Agent Orchestrator" },
  { to: "/products/data-preparation", label: "Data Preparation" },
  { to: "/products/xceed-analytics-sql-Workbench", label: "SQL Workbench" },
  { to: "/products/data-connectors", label: "Data Connectors" },
  { to: "/products/architecture", label: "Architecture" },
  { to: "/products/pricing", label: "Pricing" },
];

const storiesLinks = [
  { to: "/stories?industry=financial-services", label: "Financial Services" },
  { to: "/stories?industry=retail-services", label: "Retail & CPG" },
  { to: "/stories?role=data-analyst", label: "Data Analyst" },
  { to: "/stories?role=data-scientist", label: "Data Scientist" },
  { to: "/stories?role=data-engineer", label: "Data Engineer" },
  { to: "/stories?role=business-user", label: "Business User" },
];

const resourcesLinks = [
  { url: window.__DocumentationUrl__, label: "Documentation" },
  { url: window.__BlogUrl__, label: "Blogs" },
];

const companyLinks = [
  { to: "/about-us", label: "About Us" },
  { url: window.__CareersUrl__, label: "Careers" },
  { to: "/partners", label: "Partners" },
  { to: "/contact-us", label: "Contact Us" },
];

export default Footer;
