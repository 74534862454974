import React from "react";

import ListComponent from "@/components/listComponent/listComponent";
import Container from "@/components/ui/Container.tsx";
import PageSectionContent from "@/components/ui/PageSectionContent.tsx";
import ImageSlideshow from "../../../ImageSlideshow";

const CreateAgentFlow = () => {
  return (
    <>
      <PageSectionContent
        title="Evaluate your Agent"
        contentType="list"
        listItems={[
          "Create Experiments using Agent Experiments",
          "Out of the box support for most common evaluation metrics including LLM as a judge",
          "Versioned datasets ensure that you keep track of your test suite",
          "Track all eval runs",
          "Drill down into details of run specific information and further to test case run information",
          "Fix the failures and iterate fast",
        ]}
        bgClass=""
        mediaComponent={
          <ImageSlideshow
            images={[
              "/images/evalMetrics1.png",
              "/images/runHistory.png",
              "/images/testCaseRunDetails.png",
            ]}
            timerLimit={3000}
          />
        }
      />

      <PageSectionContent
        title="One Click Deployment and Use"
        listItems={[
          "One click deployment ensures that agents can be deployed in production at required scale",
          "Pre-integrated conversational UI makes agent instantly available to the users.",
        ]}
        contentType="list"
        imageSrc="/images/AgentChatInterfaceDark.svg"
        imageAlt="Deployment "
        preHeading="Easy to deploy and use"
        reverse={true}
        bgClass=""
      />

      <PageSectionContent
        preHeading="Monitoring"
        title="Monitoring Post Deployment"
        contentType="list"
        listItems={[
          "Support for pre-integrated agent observability",
          "Out of the box support for most common consumption metrics, trends, consumption by task/status",
          "Filter & Drill down into details by date/task/status",
          "Review details for a specific conversation thread",
        ]}
        imageSrc="/images/MetricsDashboardDark.svg"
        imageAlt="Data agents for all roles"
      />

      <PageSectionContent
        preHeading="Collaborate across all data users"
        title="Govern, Share and Collaborate"
        contentType="list"
        listItems={[
          "Share your agents with users who need access",
          "Granular access control & governance ensures users have priviledges they need",
        ]}
        reverse={true}
        imageSrc="/images/dataPreparationVisual3.svg"
        imageAlt="cynepia data connectors"
      ></PageSectionContent>
    </>
  );
};

export default CreateAgentFlow;
