import React, { useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import Button from "@/components/ui/Button";
import InputDropdown from "@/components/ui/InputDropdown";
import InputNumber from "@/components/ui/InputNumber";
import InputDateTime from "@/components/ui/InputDatetime";
import Textarea from "@/components/ui/TextArea";
import InputText from "@/components/ui/InputText";
import HeadingComponent from "@/components/ui/HeadingComponent";
import { CountryList, DialCodeList } from "@/utilityData/utilityData";
import timezones from "timezones.json";
import { getCurrentDateTime } from "@/lib/utils";

const ContactUsForm = ({ lead_source }: { lead_source: string }) => {
  const [requestOnGoing, setRequestOnGoing] = useState(false);
  const [formSubmitStatus, setFormSubmitStatus] = useState<string | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const dialCodeOptions = useMemo(
    () => DialCodeList.map((code) => ({ label: code, value: code })),
    []
  );
  const countryOptions = useMemo(
    () => CountryList.map((name) => ({ label: name, value: name })),
    []
  );
  const timezoneOptions = useMemo(
    () => timezones.map((tz) => ({ label: tz.text, value: tz.text })),
    []
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (!formRef.current) return;

      const formData = new FormData(formRef.current);
      const phone = String(formData.get("phone"));
      const dialcode = String(formData.get("dialcode"));

      const phone_with_dialcode = phone + dialcode
      const bodyParams = {
        firstname: formData.get("firstname"),
        lastname: formData.get("lastname"),
        phone_with_dialcode,
        country: formData.get("country"),
        zip: formData.get("zip"),
        scheduledate: formData.get("scheduledate"),
        last_schedule_meeting_time_zone: formData.get(
          "last_schedule_meeting_time_zone"
        ),
        email: formData.get("email"),
        lead_source,
        message: formData.get("message"),
      };
      setRequestOnGoing(true);
      const response = await fetch(
        `${window.__CreateContact__}/${btoa(JSON.stringify(bodyParams))}`,
        { method: "GET" }
      );
      const result = await response.json();
      setFormSubmitStatus(result.status === 200 ? "Success" : "Failed");
      toast.success("Form Submitted Successfully");
      if (result.status === 200) formRef.current.reset();
    } catch (error) {
      toast.error("Submission Failed");
      console.error(error);
      setFormSubmitStatus("Failed");
    } finally {
      setRequestOnGoing(false);
    }
  };

  return (
    <>
      {formSubmitStatus && (
        <div
          className={`p-4 rounded ${
            formSubmitStatus === "Success"
              ? "bg-green-100 text-green-700"
              : "bg-red-100 text-red-700"
          }`}
        >
          {formSubmitStatus === "Success"
            ? "Thank you for submitting the form. Check your mail for confirmation."
            : "Something went wrong! Please try again."}
        </div>
      )}
      <HeadingComponent level={6} className="mb-7">
        Send us a message
      </HeadingComponent>
      <form ref={formRef} onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <InputText
            placeholder="First Name"
            name="firstname"
            label="First Name"
            required
          />
          <InputText
            placeholder="Last Name *"
            name="lastname"
            label="Last Name"
            required
          />
        </div>
        <InputText
          label="Email"
          name="email"
          placeholder="Company Email *"
          required
        />
        <div className="flex flex-row gap-4">
          <div className="flex-shrink-1">
            <InputDropdown
              label="Code"
              options={dialCodeOptions}
              name="dialcode"
              placeholder="00"
            />
          </div>
          <div className="flex-grow-1">
            <InputNumber
              label="Phone"
              name="phone"
              placeholder="Phone"
              required
              showSlider={false}
            />
          </div>
        </div>
        <div className="flex gap-5 w-full">
          <div className="w-2/3">
            <InputDropdown
              label="Country"
              name="country"
              options={countryOptions}
            />
          </div>
          <div className="w-1/3">
            <InputNumber
              label="Zip Code"
              name="zip"
              placeholder="Zip Code *"
              required
            />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <InputDateTime
            name="scheduledate"
            label="Schedule Meeting"
            showTime
            minDate={getCurrentDateTime()}
            required
          />
          <InputDropdown
            label="Enter Timezone"
            name="last_schedule_meeting_time_zone"
            options={timezoneOptions}
          />
        </div>
        <Textarea
          label="Message (optional)"
          name="message"
          placeholder="Your Message"
          rows={4}
        />
        <label className="flex items-center">
          <input
            type="checkbox"
            defaultChecked
            className="mr-2"
            id="sign_up_for_newsletter"
          />
          Sign me up for newsletter
        </label>
        <Button disabled={requestOnGoing} className="w-full">
          {requestOnGoing ? "Submitting..." : "Contact Us"}
        </Button>
      </form>
      <p className="text-gray-600 text-sm mt-4">
        By submitting this form you agree to our{" "}
        <Link to="/privacy" className="text-blue-500">
          Privacy Policy
        </Link>{" "}
        and{" "}
        <Link to="/terms" className="text-blue-500">
          Terms of Use
        </Link>
        .
      </p>
    </>
  );
};

export default ContactUsForm;
