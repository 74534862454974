import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import HeadingComponent from "@/components/ui/HeadingComponent";
// @ts-ignore
import SocialIcons from "@/components/socialIcons/socialIcons";
import PreHeading from "@/components/ui/PreHeading";
import ContactUsForm from "./ContactUsForm";
import "./ContactUs.scss";

const ContactUs = () => {
  return (
    <div id="contactUsPage">
      <PageSection>
        <Container>
          <div className="min-h-screen  py-12">
            <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-10">
              <div>
                <PreHeading>Let's Connect</PreHeading>
                <HeadingComponent level={2} className="text-3xl font-bold mb-4">
                  Contact Us
                </HeadingComponent>
                <p className="text-gray-600 mb-6">
                  Fill out the form and we will reach out to you.
                </p>

                <div className="space-y-4 mb-5">
                  <div className="flex items-center gap-4 p-4 bg-white rounded ">
                    <img
                      src="/images/icons/phone.svg"
                      alt="phone"
                      className="w-6"
                    />
                    <div>
                      <HeadingComponent level={6}>Phone</HeadingComponent>
                      <p className="text-gray-600">+91 - 973 193 3997</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 p-4 bg-white rounded ">
                    <img
                      src="/images/icons/mail.svg"
                      alt="email"
                      className="w-6"
                    />
                    <div>
                      <HeadingComponent level={6}>Mail us at</HeadingComponent>
                      <p className="text-gray-600">sales@cynepia.com</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4 p-4 bg-white rounded ">
                    <img
                      src="/images/icons/location.svg"
                      alt="email"
                      className="w-6"
                    />
                    <div>
                      <HeadingComponent level={6}>Location</HeadingComponent>
                      <p className="text-gray-600">
                        Cynepia Technologies, Survey No. 58, 7, Outer Ring Rd,
                        Bellandur, Bengaluru, Karnataka 560103, INDIA.
                      </p>
                    </div>
                  </div>
                </div>
                <SocialIcons bg="rounded colored" />
              </div>

              <div className="bg-white p-6 rounded-lg border border-gray-200 ">
                <ContactUsForm lead_source="Web-Contact-Us" />
              </div>
            </div>
          </div>
        </Container>
      </PageSection>
    </div>
  );
};

export default ContactUs;
