import ContactUsSection from "@/components/contactUsSection/contactUsSection";
import CtaButtons from "@/components/ctaButtons/ctaButtons";

import HeadingComponent from "@/components/ui/HeadingComponent";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import HeroComponent from "@/components/ui/HeroComponent";
import {
  Award,
  BellRing,
  CalendarSync,
  ChartArea,
  ChartSpline,
  FileChartPie,
  Filter,
  LayoutDashboard,
  Proportions,
} from "lucide-react";
import ListOfCardsWithIcons from "@/components/ui/ListOfCardsWithIcons";
import PageSectionContent from "@/components/ui/PageSectionContent";
import Paragraph from "@/components/ui/Paragraph";

const getListingData = () => {
  return [
    {
      title: "Interactive Dashboards",
      src: "dashboard.svg",
      iconComponent: <LayoutDashboard size={20} />,
      desc: "Create, organize and share powerful executive dashboards by role or departments in minutes.",
    },
    {
      title: "Reports",
      src: "pdfReports.svg",
      desc: "Download or Schedule PDF version of a workspace as a report.",
      iconComponent: <FileChartPie size={20} />,
    },
    {
      title: "Filter / Drilldown",
      src: "filter.svg",
      desc: "Filter and Drilldown at various levels and hierarchy.",
      iconComponent: <Filter size={20} />,
    },
    {
      title: "Advanced Visualization",
      src: "desktopVisualization.svg",
      desc: "Wide range of charts and maps to select from and present the data.",
      iconComponent: <ChartArea size={20} />,
    },
    {
      title: "Flexible Online Dashboard Authoring",
      src: "badge.svg",
      desc: "Flexible and easy-to-use web-based authoring tool available to everyone to create and share reports.",
      iconComponent: <Award size={20} />,
    },
    {
      title: "Mobile View",
      src: "mobileAnalytics.svg",
      desc: "Responsive Design enables dashboard on different device formats.",
      iconComponent: <Proportions size={20} />,
    },
    {
      title: "In-memory analytics",
      src: "memoryAnalytics.svg",
      desc: "Connect to data directly in data lake and build insights instantly.",
      iconComponent: <ChartSpline size={20} />,
    },
    {
      title: "Data Alerts",
      src: "bellNotify.svg",
      desc: "Notify users of events of interest.",
      iconComponent: <BellRing size={20} />,
    },
    {
      title: "Automated refresh",
      src: "automatedRefreshGear.svg",
      desc: "Automated dashboard refresh, once the new version of the underlying data is available.",
      iconComponent: <CalendarSync size={20} />,
    },
  ];
};

const SelfServiceBI = () => {
  return (
    <div id="selfServiceBIPage">
      <PageSection>
        <Container>
          <HeroComponent
            title="Transition Into Data Driven Decision Future"
            description="Create dashboards and reports charts instantly. Stop using multiple dashboarding tools."
            ctaButtons={<CtaButtons />}
            imageSrc="/images/selfServiceBiBanner.png"
            imagePosition="right"
            layout="row"
            bgColor="bg-white"
          />
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={3}>
            Don’t leave any story behind
          </HeadingComponent>
          <Paragraph className="text-lg">
            Our powerful ad-hoc story designer lets users design compelling data
            stories using charts and maps that help decision-makers quickly
            grasp the generated insights. An interactive dashboard provides a
            variety of options for designing dynamic, mobile-friendly
            dashboards. Any user with limited technical skills can author, view,
            and engage with these data stories easily and instantly.
          </Paragraph>
        </Container>
      </PageSection>

      <PageSectionContent
        preHeading="Create Data Driven Culture"
        contentType="list"
        title="Empower everyone in your organization to use data"
        listItems={[
          "Removes friction since all data is in one place.",
          "Create visually appealing stories from a wide range of charts and visualizations.",
          "Even non-technical users can now build data stories on their own.",
        ]}
        imageSrc="/images/selfServiceBiVisual1.svg"
        imageAlt="cynepia data connectors"
      />

      <PageSectionContent
        title="Govern, Share and Collaborate"
        preHeading="All in one place"
        contentType="list"
        listItems={[
          "Ensure that everyone has access to correct data.",
          "Distributed Governance ensures instant access of data to users who need it.",
          "Notify users on events.",
        ]}
        reverse={true}
        imageSrc="/images/dataPreparationVisual3.svg"
        imageAlt="cynepia data connectors"
      />
      <PageSection>
        <Container>
          <HeadingComponent level={3} className="mb-12">
            Technical Capabilities
          </HeadingComponent>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {getListingData().map((feature) => {
              return <ListOfCardsWithIcons {...feature} />;
            })}
          </div>
        </Container>
      </PageSection>

      <ContactUsSection />
    </div>
  );
};

export default SelfServiceBI;
