import ContactUsSection from "@/components/contactUsSection/contactUsSection";
import CtaButtons from "@/components/ctaButtons/ctaButtons";
import ListComponent from "@/components/listComponent/listComponent";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import HeroComponent from "@/components/ui/HeroComponent";
import HeadingComponent from "@/components/ui/HeadingComponent";
import PreHeading from "@/components/ui/PreHeading";
import ListOfCardsWithIcons from "@/components/ui/ListOfCardsWithIcons";
import {
  Award,
  ChartCandlestick,
  Eye,
  FileBox,
  FileLock,
  FileStack,
  HandPlatter,
  SquareCode,
} from "lucide-react";
import { Person } from "@mui/icons-material";

const getListingData = () => {
  return [
    {
      title: "Single Source of Truth",
      desc: "All the ML/AI model artifacts available from one single view.",
      iconComponent: <Eye />,
    },
    {
      title: "Model Explainability",
      desc: "Quick preview of model artifacts including associated description and Explainability",
      iconComponent: <FileBox />,
    },
    {
      title: "Evaluate/Test Models",
      desc: "Evaluate and test model directly on Xceed Intelligence Platform via Playground",
      iconComponent: <ChartCandlestick />,
    },
    {
      title: "Collaborate & Govern",
      desc: "Share & Collaborate with other ML/AI team members within the organisation.",
      iconComponent: <Person />,
    },
    {
      title: "Ensure Model Auditability",
      desc: "Quickly Audit Explainability for all the revisions of the model",
      iconComponent: <Award />,
    },
    {
      title: "Enterprise Grade Security",
      desc: "Granular Governance ensures everyone has access to what is required for their specific role.",
      iconComponent: <FileLock />,
    },
    {
      title: "Versioned ML Models",
      desc: "Track every revision from the catalog revisions page.",
      iconComponent: <FileStack />,
    },
    {
      title: "Xceed Foundation Model API Client",
      desc: "Xceed foundation model APIs provide instant access to your deployed models.",
      iconComponent: <SquareCode />,
    },
    {
      title: "Serve",
      desc: "Serve all model types while optimizing your model througput/cost.",
      iconComponent: <HandPlatter />,
    },
  ];
};

const ModelCatalog = () => {
  return (
    <div id="modelCatalogPage">
      <PageSection>
        <Container>
          <HeroComponent
            title="Discover, Govern & Collaborate"
            description="360-degree visibility into all your ML/AI models, artifacts, revisions, and deployments from a single view."
            ctaButtons={<CtaButtons center={false} />}
            imageSrc="/images/model_catalog/mlCatalogBanner.svg"
            imagePosition="right"
            layout="row"
            bgColor="bg-white"
          />
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={3}>
            Organize, Govern all your ML/AI artifacts and deployments
          </HeadingComponent>
          <p className="text-lg text-gray-600">
            Xceed Model Catalog supports Classical ML and AI models with
            built-in model explainability. It also facilitates importing models
            from public repositories like Hugging Face...
          </p>
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <div className="grid lg:grid-cols-2 gap-8 items-center">
            <div>
              <PreHeading>
                Better manage your enterprise ML/AI model assets
              </PreHeading>
              <HeadingComponent level={3}>
                One place to find all your model assets
              </HeadingComponent>
              <ListComponent
                data={[
                  "All the models are instantly available to whoever needs them.",
                  "No hassle of maintaining model revisions. Find historical versions whenever needed.",
                  "One-click CPU/GPU deployment directly from the catalog.",
                  "Optimize throughput/cost by deploying multiple models on the same instance.",
                ]}
              />
            </div>
            <div className="flex justify-center bg-gray-100 rounded-lg">
              <img
                src="/images/model_catalog/modelCatalog.svg"
                alt="cynepia model catalog"
                className="w-full max-w-md"
              />
            </div>
          </div>
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <div className="grid lg:grid-cols-2 gap-8 items-center">
            <div className="flex justify-center bg-gray-100 rounded-lg">
              <img
                src="/images/model_catalog/playground.svg"
                alt="cynepia model catalog"
                className="w-full max-w-md"
              />
            </div>
            <div>
              <PreHeading>Playground</PreHeading>
              <HeadingComponent level={3}>
                Evaluate models using Playground
              </HeadingComponent>
              <ListComponent
                data={[
                  "Evaluate models in Staging before production deployment.",
                  "Compare and evaluate different model revisions.",
                ]}
              />
            </div>
          </div>
        </Container>
      </PageSection>
      <PageSection>
        <Container>
          <div className="grid lg:grid-cols-2 gap-8 items-center">
            <div>
              <PreHeading>Govern, Deploy/Serve</PreHeading>
              <HeadingComponent level={3}>Govern and Serve</HeadingComponent>
              <ListComponent
                data={[
                  "Share your data assets across all the data teams who need access to your model instantly.",
                  "Granular access control & governance with dataset access control and row-level filters within each dataset ensures that users see only the records that they are entitled to.",
                  "Deploy model instantly on any CPU/GPU of your choice",
                ]}
              />
            </div>
            <div className="flex justify-center bg-gray-100 rounded-lg p-3">
              <img
                src="/images/model_catalog/deploy_and_serve.svg"
                alt="cynepia model catalog"
                className="w-full max-w-md"
              />
            </div>
          </div>
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={3} className="mb-10">
            Technical Capabilities
          </HeadingComponent>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {getListingData().map((feature) => (
              <ListOfCardsWithIcons {...feature} />
            ))}
          </div>
        </Container>
      </PageSection>

      <ContactUsSection />
    </div>
  );
};

export default ModelCatalog;
