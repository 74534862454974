import { useState } from "react";
import { ChevronDown } from "lucide-react";

interface AccordionItem {
  id: number;
  title: string;
  DescriptionComp: any;
}

interface AccordionProps {
  items: AccordionItem[];
  onSelect: (item: AccordionItem) => void;
  selectedId: number;
}

const Accordion: React.FC<AccordionProps> = ({ items, onSelect }) => {
  const [selectedItem, setSelectedItem] = useState<AccordionItem>(items[0]);

  const handleClick = (item: AccordionItem) => {
    setSelectedItem(item);
    onSelect(item);
  };

  return (
    <div className="space-y-2">
      {items.map((item) => (
        <div key={item.id}>
          <button
            key={item.id}
            onClick={() => handleClick(item)}
            className={`font-medium text-lg border-b border-gray-100 bg-white hover:bg-gray-100 cursor-pointer flex items-center justify-between w-full p-3 text-left  transition-all`}
          >
            {item.title}
            <ChevronDown
              className={`w-5 h-5 transition-transform ${
                selectedItem.id === item.id ? "rotate-180" : ""
              }`}
            />
          </button>
          {selectedItem.id == item.id ? selectedItem.DescriptionComp : ""}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
