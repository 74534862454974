import { LinkItemList, LinkItemProps } from "../../LinkListItems/LinkListItems";

import {
  Banknote,
  Bot,
  Cable,
  ChartArea,
  Database,
  File,
  FileBox,
  PencilRuler,
  Search,
  SquareTerminal,
  Workflow,
} from "lucide-react";

const appListItems: LinkItemProps[] = [
  {
    iconComponent: <Bot size={20} />,
    title: "Xceed AI Agents",
    to: "/products/ai-agents",
    description:
      "Supercharge your enterprise by creating purpose-built agents.",
  },
  {
    title: "Self-Service BI",
    to: "/products/self-service-bi",
    description:
      "Empower Business Analysts/Users to build insights, share instantly, and drive actions.",
    iconComponent: <ChartArea size={20} />,
  },
  {
    iconComponent: <Search size={20} />,
    title: "Smart AI Discovery and Search",
    to: "/products/smart-ai-discovery-and-search",
    description:
      "Empower Business Analysts/Users to build insights, share instantly, and drive actions.",
  },
];

const CatalogueItems: LinkItemProps[] = [
  {
    iconComponent: <Database size={20} />,
    title: "Datasets",
    to: "/products/data-catalog",
    description: "Enable governed access to data that users need instantly.",
  },
  {
    iconComponent: <File size={20} />,
    title: "Files",
    to: "/products/files",
    description: "Enable governed access to data that users need instantly.",
  },
  {
    iconComponent: <FileBox size={20} />,
    title: "ML Models",
    to: "/products/model-catalog",
    description:
      "One place for all your ML & AI models. Streamline model ops including version control, governance, traceability, and deployment.",
  },
  {
    iconComponent: <Cable size={20} />,
    title: "Data Connections",
    to: "/products/data-connectors",
    description:
      "Don't leave any data behind. Connect all your enterprise data.",
  },
];

const WorkbenchItems: LinkItemProps[] = [
  {
    iconComponent: <Workflow size={20} />,
    title: "No-code Data & ML Workflows",
    to: "/products/data-preparation",
    description:
      "Low/no-code interface to build, run, and manage data/ML models instantly.",
  },
  {
    iconComponent: <SquareTerminal size={20} />,
    title: "SQL Workbench",
    to: "/products/xceed-analytics-sql-Workbench",
    description:
      "Enable data analysts to create, run, and manage SQL queries/models using Xceed SQL Workbench.",
  },
];

const OverViewList: LinkItemProps[] = [
  {
    title: "Architecture",
    description:
      "Unified AI platform architecture brings data and AI together accelerating value creation using generative AI.",
    iconComponent: <PencilRuler size={20} />,
    to: "/products/architecture",
  },
  {
    title: "Pricing",
    iconComponent: <Banknote size={20} />,
    to: "/products/pricing",
  },
];

const ProductsDropdown = () => {
  return (
    <div id="productsDropdown">
      <div className=" mx-auto">
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-3">
            <LinkItemList title="OVERVIEW" items={OverViewList} />
          </div>
          <div className="col-span-3">
            <LinkItemList title="APPSTORE" items={appListItems}></LinkItemList>
          </div>
          <div className="col-span-3">
            <LinkItemList
              title="CATALOGUE"
              items={CatalogueItems}
            ></LinkItemList>
          </div>
          <div className="col-span-3">
            <LinkItemList
              title="WORKBENCH"
              items={WorkbenchItems}
            ></LinkItemList>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsDropdown;
