import React from "react";
import { ArrowRight } from "lucide-react";
import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";

type AIFeatureSectionProps = {
  title?: string;
  description?: string;
  ctaLabel?: string;
  ctaLink?: string;
  leftImage?: string;
  rightImage?: string;
  leftBg?: string;
  rightBg?: string;
  leftWidth?: string;
  rightWidth?: string;
  containerClass?: string;
  showCTA?: boolean;
};

const AIFeatureSectionComponent: React.FC<AIFeatureSectionProps> = ({
  title = "Create your agent",
  description = "Create purpose-built AI agents effortlessly using Xceed Agent builder.",
  ctaLabel = "Explore AI Create",
  ctaLink = "#",
  leftImage = undefined,
  rightImage = "/images/agentSampleListing.svg",
  leftBg = "/images/polkadot.svg",
  rightBg = "/images/gridbg.svg",
  leftWidth = "w-[60%]",
  rightWidth = "w-[40%]",
  containerClass = "",
  showCTA = true,
}) => {
  return (
    <div
      className={`grid grid-cols-12 border border-gray-200 ${containerClass}`}
    >
      <div className="lg:col-span-4 col-span-12 border-e border-gray-200">
        <div className="px-8 py-5 h-full">
          <div className="flex justify-between flex-col h-full">
            <div>
              <div className="mb-1 font-semibold text-lg">{title}</div>
              <div className="text-gray-500">{description}</div>
            </div>
            {showCTA && (
              <Link to={ctaLink} className="flex text-gray-800 font-semibold">
                {ctaLabel} <ArrowRight className="ml-2" />
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="lg:col-span-8 col-span-12 flex flex-row">
        {leftImage && (
          <div
            className={`p-3 inline-flex justify-start w-full bg-[url(${leftBg})] bg-contain`}
          >
            <img
              src={leftImage}
              className={cn("mx-auto p-1", leftWidth)}
              alt="Left"
            />
          </div>
        )}
        {rightImage && (
          <div
            className={`p-3 inline-flex w-full justify-start bg-[url(${rightBg})] bg-cover`}
          >
            <img
              src={rightImage}
              className={cn("mx-auto p-1", rightWidth)}
              alt="Right"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AIFeatureSectionComponent;
