import React, { Component } from "react";
import SocialIcons from "../../socialIcons/socialIcons";
import MultiLevelAccordionNavItem from "../../multilevelAccordionNavItems/multiLevelAccordionNavItems";
import CtaButtons from "../../ctaButtons/ctaButtons";

export default class SideNav extends Component {
  static getLinks() {
    return [
      {
        id: "Home",
        to: "/",
        title: "Home",
      },
      {
        id: "Product",
        title: "Product",
        dropdown: [
          {
            id: "dataConnections",
            title: "Data Connections",
            to: "/products/data-connectors",
          },
          {
            id: "dataCatalogue",
            title: "Data Catalog",
            to: "/products/data-catalog",
          },
          {
            id: "dataPreparation",
            title: "Data Preparation",
            to: "/products/data-preparation",
          },
          {
            id: "sqlEditor",
            title: "SQL Workbench",
            to: "/products/xceed-analytics-sql-Workbench",
          },
          {
            id: "modelCatalog",
            title: "Model Catalog and Playground",
            to: "/products/data-catalog",
          },
          {
            id: "selfServiceBi",
            title: "Self Service BI",
            to: "/products/self-service-bi",
          },
          {
            id: "files",
            title: "Files",
            to: "/products/files",
          },
          {
            id: "smartAIDiscoveryAndSearch",
            title: "Smart AI Discovery and Search",
            to: "/products/smart-ai-discovery-and-search",
          },
          {
            id: "AI Agents",
            title: "Create Agent Workforce for your enterprise",
            to: "/products/ai-agents",
          },
          {
            id: "architecture",
            title: "Architecture",
            to: "/products/architecture",
          },
          {
            id: "pricing",
            title: "Pricing",
            to: "/products/pricing",
          },
        ],
      },
      {
        id: "Stories",
        title: "Stories",
        to: "/stories",
      },
      {
        id: "Learn",
        title: "Learn",
        dropdown: [
          {
            id: "documentation",
            title: "Documentation",
            to: window.__DocumentationUrl__,
            target: "_blank",
          },
          {
            id: "blogs",
            title: "Blogs",
            to: window.__BlogUrl__,
            target: "_blank",
          },
        ],
      },
      {
        id: "Company",
        title: "Company",
        dropdown: [
          {
            id: "aboutUs",
            title: "About Us",
            to: "/about-us",
          },
          {
            id: "careers",
            title: "Careers",
            to: "https://angel.co/company/cynepia-technologies",
            target: "_blank",
          },
          {
            id: "partners",
            title: "Partners",
            to: "/partners",
          },
          {
            id: "contactUs",
            title: "Contact Us",
            to: "/contact-us",
          },
        ],
      },
    ];
  }
  render() {
    return (
      <div
        className="sidenav-container bg-white h-full"
        id="mySidenav"
        onMouseOver={this.sidebarClick}
      >
        <div className="sidenav-container-inner container">
          <MultiLevelAccordionNavItem links={SideNav.getLinks()} />
        </div>
        <div className="sidenav-cta-links container">
          <CtaButtons />
        </div>
        <div className="container">
          <div className="ms-3 mt-3">
            <SocialIcons></SocialIcons>
          </div>
        </div>
      </div>
    );
  }
}
