import React from "react";
import PreHeading from "./PreHeading";
import HeadingComponent from "./HeadingComponent";
import ListComponent from "../listComponent/listComponent";
import Paragraph from "./Paragraph";
import PageSection from "./PageSection";
import Container from "./Container";
import { cn } from "@/lib/utils";

type PageSectionContentProps = {
  preHeading?: string;
  title: string;
  description?: string | React.ReactNode;
  listItems?: string[];
  contentType?: "list" | "paragraph";
  reverse?: boolean;
  bgClass?: string;
  gridClass?: string;
  textColor?: string;
  layout?: "row" | "column";
  /**
   * Custom width for text content when in row layout.
   * Use Tailwind width classes (e.g., "w-1/2", "w-1/3").
   * Defaults to "w-1/2" (50%) for row layout.
   */
  textWidth?: string;
  /**
   * Custom width for media section when in row layout.
   * Use Tailwind width classes (e.g., "w-1/2", "w-2/3").
   * Defaults to "w-1/2" (50%) for row layout.
   */
  mediaWidth?: string;
} & (
  | {
      imageSrc: string;
      imageAlt: string;
      mediaComponent?: never;
    }
  | {
      mediaComponent: React.ReactNode;
      imageSrc?: never;
      imageAlt?: never;
    }
);

const PageSectionContent: React.FC<PageSectionContentProps> = ({
  preHeading,
  title,
  description,
  listItems = [],
  contentType = "paragraph",
  reverse = false,
  bgClass = "bg-gray-100",
  gridClass = "bg-[url(/images/gridbg.svg)] bg-contain",
  textColor = "text-gray-700",
  layout = "row",
  textWidth = "w-full lg:w-1/2", // default for row layout
  mediaWidth = "w-full lg:w-1/2", // default for row layout
  imageSrc,
  imageAlt,
  mediaComponent,
}) => {
  const isRowLayout = layout === "row";
  // When in row layout, use provided widths; otherwise, full width.
  const textWidthClass = isRowLayout ? textWidth : "w-full";
  const mediaWidthClass = isRowLayout ? mediaWidth : "w-full";

  return (
    <PageSection>
      <Container>
        <div className="container mx-auto px-4">
          <div
            className={`flex ${
              isRowLayout
                ? `flex-col lg:flex-row ${reverse ? "lg:flex-row-reverse" : ""}`
                : "flex-col"
            } gap-8 items-start `}
          >
            {/* Text Content */}
            <div className={`${textWidthClass} space-y-4 ${textColor}`}>
              {preHeading && <PreHeading>{preHeading}</PreHeading>}
              <HeadingComponent level={3}>{title}</HeadingComponent>

              {contentType === "paragraph" && description && (
                <Paragraph className="mt-2">{description}</Paragraph>
              )}

              {contentType === "list" && listItems.length > 0 && (
                <ListComponent data={listItems} />
              )}
            </div>

            {/* Media Section */}
            <div
              className={`flex ${mediaWidthClass} justify-center p-4 rounded-lg ${bgClass}`}
            >
              {mediaComponent ? (
                mediaComponent
              ) : (
                <div
                  className={cn(
                    gridClass
                      ? "bg-[url(/images/gridbg.svg)] p-4 bg-contain"
                      : ""
                  )}
                >
                  <img
                    className="w-full object-contain"
                    src={imageSrc}
                    alt={imageAlt}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </PageSection>
  );
};

export default PageSectionContent;
