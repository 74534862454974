import { Link } from "react-router-dom";
import React, { Component } from "react";
import { CallMade } from "@mui/icons-material";

export default class AnimatedArrowIconLink extends Component {
  onHandleClick() {
    if (this.props.clickCb) {
      this.props.clickCb();
    }
  }

  render() {
    const arrowLinkContent = (
      <>
        <div className="d-flex">
          <span className="content-wrapper">{this.props.content}</span>
          <span className="icon-section">
            <span className="icon-wrapper">
              <CallMade></CallMade>
            </span>
          </span>
        </div>
      </>
    );
    return (
      <>
        {this.props.to ? (
          <Link
            to={this.props.to}
            className={
              "animated-arrow-link d-inline-block cursor-pointer " +
              this.props.classNames
            }
          >
            {arrowLinkContent}
          </Link>
        ) : (
          <span
            className={
              "animated-arrow-link d-inline-block cursor-pointer " +
              this.props.classNames
            }
            onClick={() => this.onHandleClick()}
          >
            {arrowLinkContent}
          </span>
        )}
      </>
    );
  }
}
