import React, { Component } from "react";
import { YouTube, Twitter, LinkedIn } from "@mui/icons-material";
import "./socialIcons.scss";

export default class SocialIcons extends Component {
  render() {
    return (
      <div className={"social-icons flex gap-3 " + this.props.bg || ""}>
        <span
          className="social-icon-link youtube cursor-pointer opacity-50 hover:opacity-100"
          target="_blank"
          aria-label="Youtube"
          onClick={() => {
            window.open(
              "https://www.youtube.com/channel/UCjnouT0EobXCPAQGAdtG-kw/videos",
              "_blank"
            );
          }}
        >
          <YouTube></YouTube>
        </span>
        <span
          className="social-icon-link twitter cursor-pointer opacity-50 hover:opacity-100"
          target="_blank"
          aria-label="Twitter"
          onClick={() => {
            window.open("https://twitter.com/Cynepia", "_blank");
          }}
        >
          <Twitter></Twitter>
        </span>
        <span
          className="social-icon-link linkedIn cursor-pointer opacity-50 hover:opacity-100"
          target="_blank"
          aria-label="LinkedIn"
          onClick={() => {
            window.open(
              "https://in.linkedin.com/company/cynepia-technologies",
              "_blank"
            );
          }}
        >
          <LinkedIn></LinkedIn>
        </span>
      </div>
    );
  }
}
