import React from "react";
import HeroComponent from "@/components/ui/HeroComponent";
import PageSectionContent from "@/components/ui/PageSectionContent";
import ContactUsSection from "@/components/contactUsSection/contactUsSection";
import CtaButtons from "@/components/ctaButtons/ctaButtons";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import HeadingComponent from "@/components/ui/HeadingComponent";
import Paragraph from "@/components/ui/Paragraph";

const SQLEditorView: React.FC = () => {
  return (
    <div id="dataEngineeringAndMlPage">
      {/* Hero Section */}
      <PageSection>
        <Container>
          <HeroComponent
            title="Create & Run SQL Queries directly on your datalake"
            description="Get answers to your queries instantly."
            ctaButtons={<CtaButtons center={false} />}
            imageSrc="/images/sqlEditorBanner.svg"
            layout="row"
            reverse={false}
            imagePosition="right"
            bgColor=""
            textColor="text-gray-900"
          />
        </Container>
      </PageSection>

      {/* Overview Section */}
      <PageSection>
        <Container>
          <HeadingComponent level={2}>Overview</HeadingComponent>
          <Paragraph size="lg">
            Build SQL Models and perform ad-hoc queries right within your Xceed
            Intelligence Platform. Xceed SQL Workbench is designed to boost
            analyst productivity. Integrated with data catalog, readily
            accessible schemas, summary statistics and auto-completion features
            enable analysts to write queries with ease. Additionally, the
            platform integrates a variety of execution engines/environments so
            that you can focus on extracting value from your data assets and
            creating high quality SQL models. Collaborate and share your SQL
            models effortlessly with other analysts.
          </Paragraph>
        </Container>
      </PageSection>

      {/* Section 3: Optimize Running Cost */}
      <PageSectionContent
        preHeading="OPTIMIZE FOR YOUR RUNNING COST"
        title="No need to worry about cost of your SQL warehouse platform"
        listItems={[
          "No need to worry about cost of your SQL warehouse platform.",
          "Multi-engine supports including open-source engines help analysts pick the engine that is optimal for the requirement.",
        ]}
        contentType="list"
        layout="row"
        imageSrc="/images/sqlEditorVisual2.svg"
        imageAlt="cynepia data connectors"
        textWidth="w-1/2"
        mediaWidth="w-1/2"
        textColor="text-gray-900"
      />

      {/* Section 4: Analyst Workbench */}
      <PageSectionContent
        preHeading="ANALYST WORKBENCH BUILD FOR PRODUCTIVITY"
        title="Build SQL Models"
        listItems={[
          "Create Reusable Models And Store the Models as Views",
          "Virtualize and Store results directly onto Datalake.",
          "Readily available data profile of the query output.",
          "Make the transformed data available instantly via Enterprise Data Catalog for Consumption",
        ]}
        contentType="list"
        layout="row"
        reverse={true}
        imageSrc="/images/sqlEditorVisual1.svg"
        imageAlt="cynepia data connectors"
        textWidth="w-1/2"
        mediaWidth="w-1/2"
        textColor="text-gray-900"
      />

      {/* Section 5: Collaboration */}
      <PageSectionContent
        preHeading="COLLABORATE WITH OTHER ANALYSTS USERS"
        title="Govern, Share and Collaborate"
        listItems={[
          "Share your data models across all the data teams who need access to your model instantly",
          "Never start from scratch. Reuse and build newer models on top of existing SQL models.",
        ]}
        contentType="list"
        layout="row"
        imageSrc="/images/dataPreparationVisual3.svg"
        imageAlt="cynepia data connectors"
        textWidth="w-1/2"
        mediaWidth="w-1/2"
        textColor="text-gray-900"
      />

      {/* Section 6: Automation */}
      <PageSectionContent
        preHeading="AUTOMATE TO ACCELARATE"
        title="Automate View Refresh"
        listItems={[
          "Schedule refresh of stored views.",
          "Accelerate time to value for your insights.",
          "Run periodically or trigger SQL jobs on input dataset update, alerting stakeholders for any SQL model run failures.",
        ]}
        contentType="list"
        layout="row"
        reverse={true}
        imageSrc="/images/dataPreparationVisual4.svg"
        imageAlt="cynepia data connectors"
        textWidth="w-1/2"
        mediaWidth="w-1/2"
        textColor="text-gray-900"
      />

      {/* Contact Us Section */}
      <ContactUsSection />
    </div>
  );
};

export default SQLEditorView;
