import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router

const ContactUsButton = ({ classNames, children }) => {
  const buttonClassNames = `cursor-pointer py-2 px-4 inline-flex justify-center items-center gap-x-2 text-md font-medium rounded-lg border border-gray-300 text-gray-900 hover:bg-gray-100 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none ${
    classNames ? classNames : ""
  }`;
  return (
    <Link to="/get-started">
      <button className={buttonClassNames}>{children || "Contact Us"}</button>
    </Link>
  );
};

export default ContactUsButton;
