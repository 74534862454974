import { useParams } from "react-router-dom";
import CtaButtons from "@/components/ctaButtons/ctaButtons";
import GetStartedSection from "@/components/getStartedSection/getStartedSection";
import StoryData from "@/components/pages/stories/storyList/storyData";
import "./story.scss";
import RelatedStorySection from "./relatedStorySection";
import HeroComponent from "@/components/ui/HeroComponent";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";

const Story: React.FC = () => {
  const { title } = useParams<{ title: string }>();
  const story = StoryData.getDataByDisplayUrl(title);

  if (!story) {
    return <div>Story not found</div>;
  }

  const {
    shortDescription,
    cover_img,
    ChildComp,
    relatedStoryIds,
    title: name,
  } = story;

  return (
    <div className="page " id="story-page">
      <PageSection>
        <Container>
          <HeroComponent
            title={name || ""}
            description={shortDescription}
            ctaButtons={<CtaButtons />}
            imageSrc={cover_img}
            layout="row"
            reverse={false}
          />
        </Container>
      </PageSection>
      <PageSection>
        <Container>
          <ChildComp />
        </Container>
      </PageSection>
      <PageSection>
        <Container>
          <RelatedStorySection data={relatedStoryIds} />
        </Container>
      </PageSection>
      <GetStartedSection />
    </div>
  );
};

export default Story;
