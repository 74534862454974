import { useEffect, useState } from "react";
import HeadingComponent from "./HeadingComponent";

type FilterOptions = {
  [key: string]: string[];
};

type CheckboxFilterProps = {
  links: {
    id: string;
    title: string;
    dropdown: {
      id: string;
      title: string;
    }[];
  }[];
  selectedFilters?: FilterOptions;
  onChange: (selected: FilterOptions) => void;
};

const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
  links,
  selectedFilters = {},
  onChange,
}) => {
  const [filters, setFilters] = useState<FilterOptions>(selectedFilters);

  useEffect(() => {
    setFilters(selectedFilters);
  }, [selectedFilters]);

  const handleCheckboxChange = (category: string, id: string) => {
    if (!id.trim()) return; // Prevent empty string from being added

    setFilters((prev) => {
      const updated = { ...prev };
      if (!updated[category]) updated[category] = [];

      if (updated[category].includes(id)) {
        updated[category] = updated[category].filter((item) => item !== id);
      } else {
        updated[category] = [...updated[category], id];
      }

      // Ensure no empty values exist in the array
      updated[category] = updated[category].filter(Boolean);

      onChange(updated);
      return { ...updated };
    });
  };

  return (
    <div className="p-4 w-full max-w-md bg-white rounded-lg">
      {links.map((group) => (
        <div key={group.id} className="mb-4">
          <HeadingComponent level={6}>{group.title}</HeadingComponent>
          <div className="space-y-2">
            {group.dropdown.map((item) => (
              <label key={item.id} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring focus:ring-blue-200"
                  checked={filters[group.id]?.includes(item.id) || false}
                  onChange={() => handleCheckboxChange(group.id, item.id)}
                />
                <span>{item.title}</span>
              </label>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export type { FilterOptions };
export { CheckboxFilter };
