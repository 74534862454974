import HeroComponent from "@/components/ui/HeroComponent";
import PageSection from "@/components/ui/PageSection";
import { JSX } from "react";
// @ts-ignore
import CtaButtons from "@/components/ctaButtons/ctaButtons";
import Container from "@/components/ui/Container";
import HeadingComponent from "@/components/ui/HeadingComponent";
import Paragraph from "@/components/ui/Paragraph";
import PartnershipForm from "./PartnershipForm";

interface PartnerShipBenfitsDataType {
  title: string;
  description: JSX.Element;
  imageSrc: string;
  imgSrc?: undefined;
}

const getPartnershipBenifits = () => {
  return [
    {
      title: "Channel Partners/Re-sellers",
      description: (
        <>
          <Paragraph>
            Cynepia re-sellers and channel partners are technology service
            providers that resell Xceed Intelligence platform in addition to
            their own deployment, implementation and services.
          </Paragraph>
          <Paragraph>
            Cynepia Insights Platform provides an industry leading modern BI
            architecture that enables channel providers and re-sellers to drive
            revenue by providing data integration and analytics solutions
            tailored to their customers requirements.
          </Paragraph>
        </>
      ),
      imageSrc: "/images/icons/colored/reseller.svg",
    },
    {
      title: "OEMs",
      description: (
        <>
          <Paragraph>
            Independent Software Vendors, Data Providers, laaS Cloud Providers
            can tightly integrate their solutions with Cynepia's Xceed
            Intelligence platform to provide a better solutions to common
            customers.
          </Paragraph>
          <Paragraph>
            We see a great value in providing tighter integration of our
            partners offerings along with Cynepia's platform to provide a better
            end-to-end solutions to joint customers.
          </Paragraph>
        </>
      ),
      imageSrc: "/images/icons/colored/cloudProvider.svg",
    },
    {
      title: "Technology Partners",
      description: (
        <>
          <Paragraph>
            Cynepia is committed to developing strong technology alliances in
            the Data, Analytics and Cloud infrastructure Space.
          </Paragraph>
          <Paragraph>
            We work closely with our partners to maintain compatibility with the
            most current platform releases to give our mutual customers the
            flexibility to deploy a powerful analytics solution.
          </Paragraph>
        </>
      ),
      imageSrc: "/images/icons/colored/technology.svg",
    },
  ];
};

const Partners = () => {
  return (
    <>
      <PageSection>
        <Container>
          <HeroComponent
            title="Think Partnership"
            description="We are on the mission to help enterprises innovate and
                    transition to data-driven future. The Xceed Intelligence
                    Platform enables service providers and re-sellers to generate
                    revenues by elevating customer value many-folds. Let us
                    together help enterprises accelerate and transition into data
                    driven future."
            imageSrc="/images/partnership.jpg"
            ctaButtons={<CtaButtons center={false} />}
            layout="row"
            bgColor="bg-white"
          />
        </Container>
      </PageSection>
      <PageSection>
        <Container>
          <HeadingComponent
            level={3}
            title="Partnership Benefits"
            className="mb-10"
          />
          <div className="grid gap-4">
            {getPartnershipBenifits().map((item) => (
              <PartnerShipBenifitsComponent {...item} />
            ))}
          </div>
        </Container>
      </PageSection>
      <PageSection classNames="bg-gray-50 flex justify-center">
        <div className="lg:w-[50%] md:w-[60%] w-full bg-white px-10 py-10 rounded-xl">
          <PartnershipForm />
        </div>
      </PageSection>
    </>
  );
};

export default Partners;

const PartnerShipBenifitsComponent = (data: PartnerShipBenfitsDataType) => {
  return (
    <div className="grid grid-cols-12 items-center w-full gap-6">
      <div className="lg:col-span-2 md:col-span-4 col-span-12 p-5">
        <img
          src={data.imageSrc}
          alt="Partner benefits"
          className="object-contain p-5"
        />
      </div>
      <div className="lg:col-span-10 md:col-span-8 col-span-12">
        <HeadingComponent level={6} title={data.title}></HeadingComponent>
        {data.description}
      </div>
    </div>
  );
};
