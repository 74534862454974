import { Component } from "react";
import CardList from "@/components/cardList/cardList";
import StoryData from "@/components/pages/stories/storyList/storyData";
import HeadingComponent from "@/components/ui/HeadingComponent";

interface RelatedStorySectionProps {
  data: string[]; // Assuming it's an array of story IDs
}

export default class RelatedStorySection extends Component<RelatedStorySectionProps> {
  getStoryByIds(ids: string[]) {
    const data = ids.map((id) => {
      return StoryData.getDataById(id);
    });
    return data;
  }

  render() {
    debugger;
    return (
      <div className="">
        <HeadingComponent level={3}>Related Stories</HeadingComponent>
        <CardList
          data={this.getStoryByIds(this.props.data)}
          gridLg={3}
          readMoreEnabled={true}
        />
      </div>
    );
  }
}
