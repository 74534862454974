import HeadingComponent from "./HeadingComponent";

const ListOfCardsWithIcons = (feature: {
  iconComponent: any;
  title: string;
  desc: string;
}) => {
  return (
    <div className="bg-white flex items-start">
      <div className="inline-flex mt-1 me-5 rounded-lg p-2 bg-blue-100">
        {feature.iconComponent}
      </div>
      <div className="flex-1">
        <HeadingComponent level={6}>{feature.title}</HeadingComponent>
        <p className=" text-gray-500 text-sm">{feature.desc}</p>
      </div>
    </div>
  );
};

export default ListOfCardsWithIcons;
