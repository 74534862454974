import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StoryData from "./storyList/storyData";
import CardList from "@/components/cardList/cardList";
import GetStartedSection from "@/components/getStartedSection/getStartedSection";
import CtaButtons from "@/components/ctaButtons/ctaButtons";
import HeroComponent from "@/components/ui/HeroComponent";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import HeadingComponent from "@/components/ui/HeadingComponent";
import { CheckboxFilter, FilterOptions } from "@/components/ui/CheckboxFilters";

type SearchParams = FilterOptions & {
  industry: string[];
  role: string[];
}

const getLinks = () => [
  {
    id: "industry",
    title: "By Industry",
    dropdown: [
      { id: "financial-services", title: "Financial Services" },
      { id: "retail-services", title: "Retail and CPG" },
    ],
  },
  {
    id: "role",
    title: "By Role",
    dropdown: [
      { id: "data-analyst", title: "Data Analyst" },
      { id: "data-scientist", title: "Data Scientist" },
      { id: "data-engineer", title: "Data Engineers" },
    ],
  },
];

const Stories: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getSearchParams = (): SearchParams => {
    const params = new URLSearchParams(location.search);
    return {
      industry: params.get("industry")?.split(",") || [],
      role: params.get("role")?.split(",") || [],
    };
  };

  const [selectedFilters, setSelectedFilters] =
    useState<SearchParams>(getSearchParams);
  const [storyData, setStoryData] = useState<any[]>([]);

  useEffect(() => {
    setSelectedFilters(getSearchParams());
  }, [location.search]);

  useEffect(() => {
    const data =
      selectedFilters.industry.length === 0 && selectedFilters.role.length === 0
        ? StoryData.getAll()
        : StoryData.getDataByIndustriesAndRoles(selectedFilters);
    setStoryData(data);
  }, [selectedFilters]);

  const updateUrl = (filters: SearchParams) => {
    const params = new URLSearchParams();
    if (filters.industry.length)
      params.set("industry", filters.industry.join(","));
    if (filters.role.length) params.set("role", filters.role.join(","));
    navigate({ search: params.toString() }, { replace: true });
  };

const onFilterChange = (selected: FilterOptions) => {
  const newFilters: SearchParams = {
    ...selected,
    industry: selected['industry'] || [],
    role: selected['role'] || [],
  };
  setSelectedFilters(newFilters);
  updateUrl(newFilters);
};

  return (
    <div className="page relative" id="stories-page">
      <PageSection>
        <Container>
          <HeroComponent
            ctaButtons={<CtaButtons />}
            layout="row"
            reverse={false}
            imagePosition="right"
            textColor="text-gray-900"
            bgColor="bg-white"
            title="Stories"
            imageSrc="/images/storiesBgImage.jpg"
            description="Learn about the current data challenges posed in various industries and how Cynepia provides creative and unique solutions."
          />
        </Container>
      </PageSection>
      <PageSection>
        <Container>
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-4">
              <div className="filter-section">
                <HeadingComponent level={4}>Filter</HeadingComponent>
                <CheckboxFilter
                  links={getLinks()}
                  onChange={onFilterChange}
                  selectedFilters={selectedFilters}
                />
              </div>
            </div>
            <div className="col-span-8">
              {storyData.length > 0 ? (
                <CardList data={storyData} gridLg={3} readMoreEnabled={true} />
              ) : (
                <div className="w-full border rounded-xl p-4">
                  <HeadingComponent title="No stories found." level={4} />
                </div>
              )}
            </div>
          </div>
        </Container>
      </PageSection>
      <GetStartedSection />
    </div>
  );
};

export default Stories;
