import ContactUsSection from "../../contactUsSection/contactUsSection";
import ListComponent from "@/components/listComponent/listComponent";
import "./pricing.scss";
import GetStartedButton from "@/components/ctaButtons/getStartedButton";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import HeadingComponent from "@/components/ui/HeadingComponent";
import PreHeading from "@/components/ui/PreHeading";
import { cn } from "@/lib/utils";

const getListData = () => {
  return [
    {
      title: "Free Trial",
      img: {
        src: "/images/pricingItem1.svg",
        width: "100",
      },
      description:
        "Ideal to try for two users & small dataset size. Trial limit is for 14 days.",
      listData: [
        <>
          User Limit (<u>2</u>)
        </>,
        <>Personalized Story-boards</>,
        <>Broader Connectivity</>,
        <>
          Data Catalog (<u>Limited Datasets</u>)
        </>,
        <>Wide Range of Visualization Options</>,
        <>Admin Interface for User Management</>,
        <>At the heart of your mobile stratergy</>,
        <>In-Memory analytics Engine</>,
        <>
          Data Size (<u>Upto 10GB</u>)
        </>,
        <>
          Individual Data Size (<u>Max 20MB</u>)
        </>,
      ],
    },
    {
      tag: "Most Popular",
      title: "SME",
      img: {
        src: "/images/pricingItem2.svg",
        width: "100",
      },
      description:
        "Ideal for less than 25 user & individual dataset size not exceeding 1 GB.",
      listData: [
        <>
          User Limit (<u>25</u>)
        </>,
        <>Personalized Story-boards</>,
        <>Broader Connectivity</>,
        <>
          Data Catalog (<u>Unlimited Datasets</u>)
        </>,
        <>Wide Range of Visualization Options</>,
        <>Admin Interface for User Management</>,
        <>At the heart of your mobile stratergy</>,
        <>In-Memory analytics Engine</>,
        <>
          Data Size (<u>Upto 100GB</u>)
        </>,
        <>
          Individual Data Size (<u>Max 500MB</u>)
        </>,
      ],
    },
    {
      title: "Enterprise",
      img: {
        src: "/images/pricingItem3.svg",
        width: "100",
      },
      description:
        "Built & priced optimally to suit the requirements based on # of users and size of data.",
      listData: [
        <>
          User Limit (<u>Unlimited</u>)
        </>,
        <>Personalized Story-boards</>,
        <>Broader Connectivity</>,
        <>
          Data Catalog (<u>Unlimited Datasets</u>)
        </>,
        <>Wide Range of Visualization Options</>,
        <>Admin Interface for User Management</>,
        <>At the heart of your mobile stratergy</>,
        <>In-Memory analytics Engine</>,
        <>
          Data Size (<u>Unlimited</u>)
        </>,
        <>
          Individual Data Size (<u>Unlimited</u>)
        </>,
      ],
    },
  ];
};

export default function Pricing() {
  return (
    <div className="page" id="pricing-page">
      <PageSection classNames="pt-50">
        <Container>
          <div className="flex justify-center g-3 mb-10">
            <div className="col-xl-6 col-lg-6 col-md-12 text-center">
              <HeadingComponent level={1}>Pricing</HeadingComponent>
              <PreHeading>Choose a Plan that fit's your scale</PreHeading>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-0 gap-3 items-stretch justify-center border border-gray-50 ">
            {getListData().map((item, i) => {
              const { title, img, listData, tag } = item;
              return (
                <div
                  className={cn(
                    i != getListData().length - 1
                      ? "border-e border-gray-100"
                      : "",
                    "p-3"
                  )}
                  key={i}
                >
                  <div className="border border-gray-200 rounded-3xl py-10 px-8 ">
                    <div className="flex items-center justify-between">
                      <HeadingComponent level={6} weight="light">
                        {title}
                      </HeadingComponent>
                      {tag ? (
                        <div className="inline px-3 py-1 text-blue-500 bg-blue-50 border border-blue-500 rounded-lg text-sm">
                          {tag}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex items-center gap-3 img-icon-wrapper text-center my-10 ">
                      <div className="w-[80px]">
                        <img
                          src={img.src}
                          alt="cynepia pricing"
                          className="object-contain"
                        />
                      </div>
                    </div>
                    <div className="description mb-3 text-gray-500">
                      {item.description}
                    </div>
                    <GetStartedButton classNames={"mb-4 w-full"} />
                    <div className="benefits-section mb-5">
                      <div className="mb-4 bold">Key Benefits</div>
                      <ListComponent data={listData} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Container>
      </PageSection>

      <ContactUsSection />
    </div>
  );
}
