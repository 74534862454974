import React, { ReactNode, ElementType } from "react";
import clsx from "clsx";

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  className?: string;
  color?: string;
  weight?:
    | "light"
    | "normal"
    | "medium"
    | "semibold"
    | "bold"
    | "extrabold"
    | "black";
  transform?: "uppercase" | "lowercase" | "capitalize" | "normal-case";
  letterSpacing?: "tighter" | "tight" | "normal" | "wide" | "wider" | "widest";
  responsive?: boolean;
  title?: string;
  children?: ReactNode;
}

const headingSizes = {
  1: "text-4xl sm:text-5xl md:text-6xl",
  2: "text-3xl sm:text-4xl md:text-5xl",
  3: "text-2xl sm:text-3xl md:text-4xl",
  4: "text-xl sm:text-2xl md:text-3xl",
  5: "text-lg sm:text-xl md:text-2xl",
  6: "text-base sm:text-lg md:text-xl",
};

const nonResponsiveSizes = {
  1: "text-4xl",
  2: "text-3xl",
  3: "text-2xl",
  4: "text-xl",
  5: "text-lg",
  6: "text-base",
};

const headingMargins = {
  1: "mb-8",
  2: "mb-7",
  3: "mb-6",
  4: "mb-5",
  5: "mb-2",
  6: "mb-2",
};

const HeadingComponent: React.FC<HeadingProps> = ({
  level,
  title,
  children,
  className = "",
  color = "text-gray-900",
  weight = "semibold",
  transform = "normal-case",
  letterSpacing = "normal",
  responsive = true,
  ...props
}) => {
  const Tag: ElementType = `h${level}`;

  const baseStyles = clsx(
    color,
    `font-${weight}`,
    `tracking-${letterSpacing}`,
    transform,
    responsive ? headingSizes[level] : nonResponsiveSizes[level],
    headingMargins[level],
    className
  );

  return (
    <Tag className={baseStyles} {...props}>
      {title ? title : ""}
      {children}
    </Tag>
  );
};

export default HeadingComponent;
