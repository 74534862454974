import React from "react";
import { cn } from "@/lib/utils";

type InputSize = "sm" | "md" | "lg";
type InputState = "default" | "error" | "success" | "disabled";

type InputDateTimeProps = {
  label?: string;
  name?: string;
  placeholder?: string;
  size?: InputSize;
  state?: InputState;
  minDate?: string;
  maxDate?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  showTime?: boolean;
  className?: string;
  required?: boolean;
  border?: string;
};

const sizeClasses: Record<InputSize, string> = {
  sm: "px-2 py-1 text-sm",
  md: "px-3 py-2 text-base",
  lg: "px-4 py-3 text-lg",
};

const stateClasses: Record<InputState, string> = {
  default: "border-gray-300 focus:ring-blue-500",
  error: "border-red-500 focus:ring-red-500",
  success: "border-green-500 focus:ring-green-500",
  disabled: "border-gray-300 bg-gray-100 cursor-not-allowed text-gray-500",
};

const InputDateTime: React.FC<InputDateTimeProps> = ({
  label,
  name,
  placeholder,
  size = "md",
  state = "default",
  minDate,
  maxDate,
  value,
  onChange,
  disabled = false,
  showTime = false,
  className = "",
  required,
  border = "border border-gray-300",
}) => {
  return (
    <div className="flex flex-col space-y-1 w-full">
      {label && <label className="text-sm font-medium">{label}</label>}
      <div className={cn("relative flex items-center", className)}>
        <input
          type={showTime ? "datetime-local" : "date"}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          min={minDate}
          max={maxDate}
          required={required}
          className={cn(
            "w-full rounded-md outline-none transition-all",
            sizeClasses[size],
            "focus:ring-2",
            stateClasses[state],
            border,
            "pr-3"
          )}
        />
      </div>
    </div>
  );
};

export default InputDateTime;
