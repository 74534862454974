import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import ListComponent from "@/components/listComponent/listComponent";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container.tsx";
import HeadingComponent from "@/components/ui/HeadingComponent.tsx";
import PreHeading from "@/components/ui/PreHeading.tsx";
import Accordion from "@/components/ui/Accordion.tsx";
import Button from "@/components/ui/Button.tsx";

function getListContent(list) {
  return <ListComponent data={list} className="mb-6" />;
}

function DataConnectorList() {
  const listData = [
    "Connect to any databases or cloud apps or REST api sources.",
    "Consolidate your data into a centralised data lake seamlessly.",
    "Get notified of changes in schema and formats in source systems on refresh, helping you take actions to minimise downtime of downstream models and reports.",
    "All your data is replicated and versioned in datalake of your choice ensuring auditability over time.",
  ];
  return (
    <div className="ms-4">
      {getListContent(listData)}
      <Link to="/products/data-connectors">
        <Button variant="outline">Learn More</Button>
      </Link>
    </div>
  );
}

function CatalogueList() {
  const listData = [
    "Easily discover and understand your data",
    "Organise, add documentation to your data so that everyone can find the right dataset.",
    "Get notified about column metadata changes, new revisions and understand the impact.",
    "Instant access to all column level descriptive statistics.",
    "Set up granular row level filter to ensure visibility by role.",
    "Democratize access to your data.",
  ];
  return (
    <div className="ms-4">
      {getListContent(listData)}
      <Link to="/products/data-connectors">
        <Button variant="outline">Learn More</Button>
      </Link>
    </div>
  );
}

function WorkflowList() {
  const listData = [
    "Get your data workflows from implementation to production in minutes.",
    "Create high quality data models for your valuable use cases effortlessly and efficiently.",
    "No need for coding and deployment skills. Any one can be a data engineer.",
    "Publish data directly to Xceed Data Catalog. No Integration required.",
  ];
  return (
    <div className="ms-4">
      {getListContent(listData)}
      <Link to="/products/data-preparation">
        <Button variant="outline">Learn More</Button>
      </Link>
    </div>
  );
}

function ServiceBIList() {
  const listData = [
    "Dont leave any story behind",
    "Any one can now create dashboards and reports instantly.",
    "Stop using multiple dashboarding tools.",
    "Filter/Drill down/Drill through the story at various levels of hierarchy/dimension",
    "Wide range of charts and maps to select from and present the data.",
  ];
  return (
    <div className="ms-4">
      {getListContent(listData)}
      <Link to="/products/self-service-bi">
        <Button variant="outline">Learn More</Button>
      </Link>
    </div>
  );
}

function AiSearchList() {
  const listData = [
    "Smart Search using Keyword Matching & Semantic Understanding via vector search.",
    "360 degree visibility of all your enterprise data and AI assets from a single page.",
    "Governed & Secure search ensures users can only discover the assets they have access to.",
  ];

  return (
    <div className="ms-4">
      {getListContent(listData)}
      <Link to="/products/smart-ai-discovery-and-search">
        <Button variant="outline">Learn More</Button>
      </Link>
    </div>
  );
}

function ModelCatalogueList() {
  const listData = [
    "360 degree visibility into all your ML/AI models, artificats, revisions and deployments",
    "Suports both classical ML and NLP models, Import pre-trained models from huggingface",
    "Leverage Playground application to test and compare different models before deploying in production",
    "Deploy any model type (custom/oss/fine-tuned) from the catalog on both CPU and GPU",
    "Built in access-control and governance ensures users can only discover the assets they have access to.",
  ];

  return (
    <div className="ms-4">
      {getListContent(listData)}
      <Link to="/products/smart-ai-discovery-and-search">
        <Button variant="outline">Learn More</Button>
      </Link>
    </div>
  );
}

function AIAgentFeatureList() {
  const listData = [
    "Agent Builder helps create agents via no code interface",
    "Evaluate and Test Agents using Agent Playground & Agent Experiment",
    "Support for out-of-the box metrics and LLM as a judge",
    "One click deployment",
    "Conversational UI experience for serving agents in production",
    "Governed & Secure search ensures users can only discover the agents they have access to.",
    "Pre-built data agents for instant deployment",
  ];

  return (
    <div className="ms-4">
      {getListContent(listData)}
      <Link to="/products/ai-agents">
        <Button variant="outline">Learn More</Button>
      </Link>
    </div>
  );
}

const getData = () => {
  return [
    {
      id: 0,
      title: "Get all your data together.",
      DescriptionComp: <DataConnectorList />,
      imgSrc: "/images/AllDataConnectorsVisual.png",
    },
    {
      id: 1,
      title: "Catalog your data.",
      DescriptionComp: <CatalogueList />,
      imgSrc: "/images/dataCatalogueVisual2.svg",
    },
    {
      id: 2,
      title: "Productionize data workflows effortlessly.",
      DescriptionComp: <WorkflowList />,
      imgSrc: "/images/dataPreparationVisual2.svg",
    },
    {
      id: 3,
      title: "Catalog & serve your models",
      DescriptionComp: <ModelCatalogueList />,
      imgSrc: "/images/model_catalog/model_catalog_capability.svg",
    },
    {
      id: 4,
      title: "Create & Share beautiful stories/dashboards instantly",
      DescriptionComp: <ServiceBIList />,
      imgSrc: "/images/selfServiceBICoverImage.svg",
    },
    {
      id: 5,
      title: "Smart AI Discovery and Search",
      DescriptionComp: <AiSearchList />,
      imgSrc: "/images/searchFeature.svg",
    },
    {
      id: 6,
      title: "Build AI Agents for your enteprise",
      DescriptionComp: <AIAgentFeatureList />,
      imgSrc: "/images/agentConversation.svg",
    },
  ];
};

const AllFeatures = () => {
  const [selectedItem, setSelectedItem] = useState(getData()[0]);
  return (
    <PageSection>
      <Container>
        <PreHeading>Platform Highlights</PreHeading>
        <HeadingComponent level={3} className="mb-5">
          All In One Highlights
        </HeadingComponent>
        <div className="grid grid-cols-12 items-stretch justify-between gap-5">
          <div className="lg:col-span-4 col-span-12 ">
            <Accordion
              items={getData()}
              onSelect={(item) => setSelectedItem(item)}
            ></Accordion>
          </div>
          <div className="lg:col-span-8 col-span-12 h-[500px] flex lg:justify-end justify-center">
            <div className="w-[80%] mh-[80%] flex items-center justify-center bg-gray-100 rounded-3xl overflow-hidden">
              <div className="bg-[url(/images/gridbg.svg)] h-full w-full bg-cover flex items-center justify-center p-5">
                <img
                  src={selectedItem.imgSrc}
                  className="w-full h-full max-w-full max-h-full object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </PageSection>
  );
};

export default AllFeatures;
