import { cn } from "@/lib/utils";
import React, { ReactNode } from "react";

interface PageSectionProps {
  children: ReactNode;
  classNames?: string;
}

const PageSection: React.FC<PageSectionProps> = ({
  children,
  classNames = "",
}) => {
  return <div className={cn(classNames, "py-20")}>{children}</div>;
};

export default PageSection;
