import React from "react";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import HeroComponent from "@/components/ui/HeroComponent";
import PageSectionContent from "@/components/ui/PageSectionContent";
import HeadingComponent from "@/components/ui/HeadingComponent";
import Paragraph from "@/components/ui/Paragraph";
import CtaButtons from "@/components/ctaButtons/ctaButtons";
import ContactUsSection from "@/components/contactUsSection/contactUsSection";
import ListOfCardsWithIcons from "@/components/ui/ListOfCardsWithIcons";
import {
  BellRing,
  Database,
  FileClock,
  FolderSync,
  SquareStack,
} from "lucide-react";

const getListingData = () => {
  return [
    {
      title: "Bring all your data together",
      iconComponent: <Database />,
      desc: "All the data available in the data lake.",
    },
    {
      title: "Periodic/Change Based Data Replication",
      iconComponent: <FileClock />,
      desc: "Automated replication ensures your data to ensure your data is available for analysis instantly.",
    },
    {
      title: "Historical Snapshots",
      iconComponent: <SquareStack />,
      desc: "Your data is versioned always, No need to take data snapshots of your important source datasets.",
    },
    {
      title: "Automate Data Refresh",
      iconComponent: <FolderSync />,
      desc: "Run Periodically or Trigger Datasource refresh Job on all remote data connectors. Alert data owners for all refresh.",
    },
    {
      title: "Know Source Schema Changes Instantly",
      iconComponent: <BellRing />,
      desc: "Metadata change alert ensures you are on top of schema changes at source.",
    },
  ];
};

const DataConnector: React.FC = () => {
  return (
    <>
      <PageSection>
        <Container>
          <HeroComponent
            title="Connect all your Data Sources in minutes"
            description="Wide range of plug-n-play connectors to help you move data into the datalake rapidly"
            ctaButtons={<CtaButtons />}
            imageSrc="/images/dataConnectorsBanner.svg"
            layout="row"
            reverse={false}
            imagePosition="right"
            bgColor=""
            textColor="text-gray-900"
          />
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={2}>
            Don’t Leave any data behind, connect all your datasources
          </HeadingComponent>
          <Paragraph size="lg">
            Xceed Data Connections provides an easy way to connect to all your
            databases/data sources. Xceed Intelligence Platform no-code data
            connectors helps connect data from bespoke business applications
            including CRM Systems, HR Systems and various Databases and
            consolidates all your data in a centralized datalake. It further
            normalizes the data in a common storage format across wide range of
            structured and semi-structured data sources. Automate periodic pulls
            of data, extraction of metadata from these sources and auto-updates
            Xceed Data Catalogue seamlessly with the new arrival,
            update/notifies all the downstream data workflows/users providing
            seemless end-to-end automation experience. Our Custom Solutions team
            can help onboard long-tail of bespoke business applications rapidly
            ensuring no data is left behind.
          </Paragraph>
        </Container>
      </PageSection>

      <PageSectionContent
        preHeading="Bring all your datasources together in hours"
        title="Connect all your datasources"
        listItems={[
          "Consolidate your data into a centralized data lake seamlessly.",
          "Wide range of connectors to over 25+ data sources including local files, cloud storages such as Amazon S3, SQL databases such as MS SQL, Oracle, NoSQL Databases such as ElasticSearch as well as API sources such as Twitter.",
          "Hassle-free intregration without having to understand various communication protocols and data formats.",
        ]}
        contentType="list"
        layout="row"
        imageSrc="/images/dataConnectorsVisual1.svg"
        imageAlt="cynepia data connectors"
        textWidth="w-1/2"
        mediaWidth="w-1/2"
        textColor="text-gray-900"
      />

      <PageSectionContent
        preHeading="Pre-empt breakages due to schema changes"
        title="Stay on top Schema Changes"
        listItems={[
          "Source Data Systems are dynamic and changes can be fast paced and often unintentional breaking the automation.",
          "Get notified of changes in schema and formats in source systems on refresh, Take actions to minimize downtime of downstream models and reports.",
        ]}
        contentType="list"
        layout="row"
        reverse
        imageSrc="/images/dataConnectorsVisual3.svg"
        imageAlt="cynepia data connectors"
        textWidth="w-1/2"
        mediaWidth="w-1/2"
        textColor="text-gray-900"
      />

      <PageSectionContent
        preHeading="Improve ROI on Data Integration"
        title="Fully Automated, Extensible, Auditable"
        listItems={[
          "All your data is replicated and versioned in datalake of your choice ensuring auditability over time.",
          "Select only tables/sources that you want to replicate.",
          "Automated data refresh ensures better ROI on your resource cost.",
          "Custom Solutions to onboard long-tail of bespoke business applications within days.",
        ]}
        contentType="list"
        layout="row"
        imageSrc="/images/dataConnectorsVisual2.svg"
        imageAlt="cynepia data connectors"
        textWidth="w-1/2"
        mediaWidth="w-1/2"
        textColor="text-gray-900"
      />

      <PageSection>
        <Container>
          <HeadingComponent level={3} className="mb-12">
            Technical Capabilities
          </HeadingComponent>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {getListingData().map((feature) => {
              return <ListOfCardsWithIcons {...feature} />;
            })}
          </div>
        </Container>
      </PageSection>
      <ContactUsSection />
    </>
  );
};

export default DataConnector;
