import ContactUsSection from "@/components/contactUsSection/contactUsSection";
import CtaButtons from "@/components/ctaButtons/ctaButtons";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import HeroComponent from "@/components/ui/HeroComponent";
import HeadingComponent from "@/components/ui/HeadingComponent";
import ListOfCardsWithIcons from "@/components/ui/ListOfCardsWithIcons";
import {
  DollarSign,
  Eye,
  Filter,
  FolderLock,
  TextQuote,
  TextSearch,
} from "lucide-react";
import PageSectionContent from "@/components/ui/PageSectionContent";

const getListingData = () => {
  return [
    {
      iconComponent: <Eye />,
      title: "360 Degree Visibility",
      desc: "Discover all your enterprise Data & AI assets from a single page.",
    },
    {
      iconComponent: <TextQuote />,

      title: "Automatic Vector Indexing",
      desc: "Automatically generate embeddings using popular open-source embedding models and vector databases.",
    },
    {
      iconComponent: <Filter />,

      title: "Filter by Asset Type",
      desc: "Only fetch assets that match a given asset type.",
    },
    {
      iconComponent: <TextSearch />,

      title: "Vector & Keyword Search",
      desc: "A combination of semantic search and keyword search to improve search relevance.",
    },
    {
      iconComponent: <FolderLock />,

      title: "Governed & Secure",
      desc: "Granular Governance ensures everyone has access to what is required for their specific role.",
    },
    {
      iconComponent: <DollarSign />,

      title: "Save Cost",
      desc: "Hosted open-source embedding model, vector database ensures no additional provider cost while users search.",
    },
  ];
};

const DataCatalogue = () => {
  return (
    <div id="dataCataloguePage">
      <PageSection>
        <Container>
          <HeroComponent
            title="Find, Govern & Collaborate"
            description="Effectively access & govern information about your data all from a single view."
            ctaButtons={<CtaButtons center={false} />}
            imageSrc="/images/dataCatalogueBanner.svg"
            imagePosition="right"
            layout="row"
            bgColor="bg-white"
          />
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={3}>
            Save time searching, quickly find any data you are searching for
          </HeadingComponent>
          <p className="text-lg">
            Xceed Data Catalog helps enterprise users find the data they need
            quickly. It further helps data owners govern data effectively by
            identify, track and govern sensitive information by ensuring that
            only the data that is required is shared. Be it raw datasets
            ingested in your datalake/data warehouse, or the feature data from
            various data workflows, discover all your data from a single view.
            Build dashboards for BI, New ML models or discover metadata, data
            revisions or download data for further analysis, we have it all
            covered.
          </p>
        </Container>
      </PageSection>

      <PageSectionContent
        title="One place to find all your data assets"
        preHeading="Better manage your enterprise data assets"
        listItems={[
          "All the data available in the data lake is available instantly to whoever needs it.",
          "No hassle of maintaining dataset revisions...",
          "Single source of truth across all use cases.",
          "Query your data, right from the catalogue.",
        ]}
        contentType="list"
        imageSrc="/images/dataCatalogueVisual1.svg"
        imageAlt="Data Catalogue"
        gridClass=""
        bgClass=""
      />

      <PageSectionContent
        preHeading="Improve ROI on Data Quality"
        title="Stay on top of schema changes"
        listItems={[
          "Stay on top of your data quality goals using quick data snapshots and schema view.",
          "Automated Data Profile helps you keep track of shape and quality of important data fields.",
          "Data Quality Alerts help you be notified if the shape of any of the important field changes with data refresh.",
        ]}
        contentType="list"
        imageSrc="/images/dataCatalogueVisual2.svg"
        imageAlt="Data Catalogue"
        reverse={true}
        bgClass=""
      />
      <PageSectionContent
        preHeading="Define, Manage and Share with Confidence"
        title="Stay on top of data quality issues"
        listItems={[
          "Reduce Data downtime with our integrated approach to monitoring data product quality",
          "Track and Manage failures over time. Spot datasets and columns with reliability issues in real-time and take effective action",
          "Ensure that all stackholders are informed on data quality issues instantly. Never loose trust.",
          "Integrated into the Unified Data Platform, provides 360 view of all the dataset operations",
        ]}
        contentType="list"
        imageSrc="/images/dataCatalogueVisual4.svg"
        imageAlt="Data Catalogue"
        reverse={false}
        bgClass=""
        gridClass=""
      />
      <PageSectionContent
        preHeading="Collaborate across all data users"
        title="Govern, Share and Collaborate"
        listItems={[
          "Share your data assets across all the data teams who need access to your model instantly.",
          "Granular access control & governance with dataset access control and row-level filters within each dataset ensures that users see only the records that they are entitled to..",
          "Notify users on data refreshes instantly.",
          "Add a custom data source within days.",
        ]}
        contentType="list"
        imageSrc="/images/dataCatalogueVisual3.svg"
        imageAlt="Data Catalogue"
        reverse={true}
        bgClass=""
        gridClass=""
      />

      <PageSection>
        <Container>
          <HeadingComponent level={3} className="mb-10">
            Technical Capabilities
          </HeadingComponent>
          <div className="grid md:grid-cols-3 gap-6">
            {getListingData().map((item) => (
              <ListOfCardsWithIcons {...item} />
            ))}
          </div>
        </Container>
      </PageSection>

      <ContactUsSection />
    </div>
  );
};

export default DataCatalogue;
