import Button from "../ui/Button";
import InputText from "../ui/InputText";

export default function InputWithButton(props: {
  inputPlaceholder: string;
  inputType: "text";
  required: boolean;
  inputName: string;
  buttonType: string;
}) {
  return (
    <div className="inputWithButton bg-white flex items-center justify-between rounded-lg w-full">
      <InputText
        placeholder={props.inputPlaceholder}
        focused=""
        required
        className="text-gray-800"
        border=""
      />

      <Button size="md" className="m-1">
        {" "}
        Subscribe
      </Button>
    </div>
  );
}
