import { useState, useEffect, useRef } from "react";
import { ChevronDown, Check } from "lucide-react";

interface Option {
  label: string;
  value: string;
}

interface InputDropdownProps {
  options: Option[];
  placeholder?: string;
  onChange?: (selected: Option | null) => void;
  value?: Option | null;
  className?: string;
  name: string;
  label?: string;
  required?: boolean;
}

export default function InputDropdown({
  options,
  placeholder = "Select an option",
  onChange,
  value: propValue,
  className,
  name,
  label,
  required,
}: InputDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedValue, setSelectedValue] = useState<Option | null>(
    propValue || null
  );
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) =>
        option.label.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, options]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={`relative w-full ${className}`} ref={dropdownRef}>
      {label && <label className="text-sm font-medium">{label}</label>}
      <div
        className="flex items-center justify-between border border-gray-300 rounded-lg px-4 py-2 cursor-pointer bg-white focus:ring-2 focus:ring-blue-500"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={selectedValue ? "" : "text-gray-400"}>
          {selectedValue ? selectedValue.label : placeholder}
        </span>
        <ChevronDown size={20} />
      </div>
      {isOpen && (
        <div className="absolute left-0 right-0 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
          <input
            type="text"
            className="w-full p-2 border-b border-gray-200 focus:outline-none"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <ul className="max-h-60 overflow-y-auto">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li
                  key={option.value}
                  className="flex items-center justify-between px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setSelectedValue(option);
                    if (onChange) {
                      onChange(option);
                    }
                    setIsOpen(false);
                  }}
                >
                  {option.label}
                  {selectedValue?.value === option.value && <Check size={16} />}
                </li>
              ))
            ) : (
              <li className="px-4 py-2 text-gray-500">No options found</li>
            )}
          </ul>
        </div>
      )}
      {/* Hidden input for form submission */}
      <input
        type="hidden"
        name={name}
        value={selectedValue?.value || ""}
        required={required}
      />
    </div>
  );
}
