import React, { Component } from "react";
import "./quotedText.scss";
import Container from "@/components/ui/Container.tsx";
import PageSection from "@/components/ui/PageSection.tsx";
import HeadingComponent from "@/components/ui/HeadingComponent.tsx";

export default class CynepiaClientsQuotations extends Component {
  render() {
    return (
      <PageSection>
        <Container className="bg-gray-100 p-10 rounded-xl">
          <div className="flex justify-center text-center">
            <div>
              <HeadingComponent level={3}>
                What are our Customers Saying ?
              </HeadingComponent>
              <div className="col-lg-12">
                <div className="quoted-text-wrapper position-relative flex text-center">
                  <div className="quoted-icon position-relative"></div>
                  <div className="quoted-text ">
                    <div className="text text-md mt-3">
                      <p>
                        Cynepia helped us meet our immediate objectives of
                        bringing data together various financial and IT systems
                        together, which in-turn helped improve various data and
                        financial processes, helping us achieve the speed and
                        efficiency with those processes. The more we were
                        exposed to the same, more we understood and learnt about
                        what we can achieve and now have embedded Xceed
                        Intelligence Platform into various processes including
                        sanctions verification, branch performance automation,
                        cross-sell, financial and hr processes.
                      </p>
                      <p>
                        More specifically automation of these data and business
                        processes have saved us hours of manual spreadsheet work
                        and helped us achieve organisation growth targets while
                        breaking the correlation between workforce and those
                        respective processes.
                      </p>
                    </div>

                    <div className="font-semibold mt-3">
                      - CIO of a large Financial Institution
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </PageSection>
    );
  }
}
