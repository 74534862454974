import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ChevronDown } from "lucide-react";

const NavItem = ({ link, isActive, onClick, navigate }) => {
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const hasDropdown = Boolean(link.dropdown);

  // Close the dropdown if a click occurs outside of the button or dropdown.
  useEffect(() => {
    if (!isActive) return;

    const handleClickOutside = (e) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        onClick(); // This toggles the dropdown off.
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isActive, onClick]);

  // If there is a dropdown, we intercept clicks.
  const handleItemClick = (e) => {
    if (hasDropdown) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <li className="text-gray-600">
      {link.to && !hasDropdown ? (
        <Link
          to={link.to}
          className="nav-link px-3 py-2 rounded-md hover:bg-gray-100 cursor-pointer"
        >
          {link.title}
        </Link>
      ) : (
        <button
          ref={buttonRef}
          onClick={handleItemClick}
          className={`nav-link px-3 py-2 rounded-md hover:bg-gray-100 flex items-center cursor-pointer ${
            isActive ? "active" : ""
          }`}
          aria-haspopup="true"
          aria-expanded={isActive}
        >
          <span>{link.title}</span>
          {hasDropdown && <ChevronDown size={16} className="ml-1" />}
        </button>
      )}
      {hasDropdown && isActive && (
        <div
          ref={dropdownRef}
          className="dropdown-menu absolute top-full left-0 right-0 mt-2 w-fit bg-white shadow-lg rounded-3xl z-50 px-10 py-8 border border-gray-200"
        >
          {link.dropdown}
        </div>
      )}
    </li>
  );
};

export default NavItem;
