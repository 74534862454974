import React from "react";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import HeadingComponent from "@/components/ui/HeadingComponent";
import PreHeading from "@/components/ui/PreHeading";

const valuePropsition = () => {
  return (
    <PageSection>
      <Container className="bg-gray-50 rounded-2xl px-10 py-8">
        <div className="flex flex-col ">
          <div className="col-md-12">
            <PreHeading>Xceed Intelligence Platform</PreHeading>
            <HeadingComponent
              level={4}
              className="pre-heading-highlight fw-bold"
            >
              All-in-One Low Code, Integrated Data & AI Platform
            </HeadingComponent>
            <p>
              Enterprises are loosing ROI on their data and AI use-cases by
              trying to integrate and manage multiple micro applications and
              point solutions . A lot more time is spent trying to put the
              fragmented solutions/applications together than accelerating value
              from their data and AI use-cases. It is now obstructing
              transformational benefits it can otherwise acheive by leveraging
              generative AI.
            </p>
            <p>
              <span className="text-blue-500 font-medium">
                Xceed Intelligence Platform{" "}
              </span>{" "}
              is an{" "}
              <span className="text-blue-500 font-medium">
                Integrated Data & AI platform{" "}
              </span>{" "}
              that brings together a unique set of capabilities to accelerate
              your data and AI use cases.{" "}
            </p>
          </div>
          <div className="col-md-12">
            <div className="image-wrapper d-flex align-items-center justify-content-center">
              <img
                src="images/cynepia_integrated_tool.svg"
                width="100%"
                alt="cynepia_integrated_tool"
              ></img>
            </div>
          </div>
        </div>
      </Container>
    </PageSection>
  );
};

export default valuePropsition;
