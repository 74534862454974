import React from "react";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import HeroComponent from "@/components/ui/HeroComponent";
import HeadingComponent from "@/components/ui/HeadingComponent";
import Paragraph from "@/components/ui/Paragraph";
import PageSectionContent from "@/components/ui/PageSectionContent";
import CtaButtons from "@/components/ctaButtons/ctaButtons";
import ContactUsSection from "@/components/contactUsSection/contactUsSection";
import ListOfCardsWithIcons from "@/components/ui/ListOfCardsWithIcons";
import {
  Bug,
  FileBadge,
  FileCode,
  GitFork,
  GitMerge,
  ToyBrick,
  Users,
  Workflow,
} from "lucide-react";

const DataEngineeringAndMl: React.FC = () => {
  const getListingData = () => {
    return [
      {
        iconComponent: <ToyBrick />,
        title: "No Code",
        desc: "Visual Designer enables users to create modular, fairly complex data pipelines without writing single line of code.",
      },
      {
        iconComponent: <GitFork />,
        title: "Visual Flow Representation",
        desc: "Visual workflow representation ensures readable, documented data pipelines all the time.",
      },
      {
        iconComponent: <FileCode />,
        title: "Write Python Code",
        desc: "Data engineers can write custom python logic like before where ever required.",
      },
      {
        iconComponent: <Users />,
        title: "Collaborate & Govern",
        desc: "Single source of truth ensures better collaboration between data engineers, business analyst and data scientists.",
      },
      {
        iconComponent: <FileCode />,
        title: "Ensure Reliability & Quality",
        desc: "Fetch Data Profile at every step to ensure accuracy and correctness of final outcome.",
      },
      {
        iconComponent: <FileBadge />,
        title: "Modular & Flexible",
        desc: "Flexibly change to adapt to changing business logic over time.",
      },
      {
        iconComponent: <Workflow />,
        title: "Automate Data Pipeline Jobs",
        desc: "Run Periodically or Trigger Pipeline Job on Input Dataset Update, Alert stackholders for any pipeline failures.",
      },
      {
        iconComponent: <GitMerge />,
        title: "Versioned Data Models",
        desc: "Pre-integrated with datalake and data catalogue ensures output versions are trackable and auditable.",
      },
      {
        iconComponent: <Bug />,
        title: "Troubleshoot & Debug",
        desc: "A wide range of tools to help trouble shoot the pipeline including warnings, column profile, run logs.",
      },
    ];
  };

  return (
    <div id="dataEngineeringAndMlPage">
      <PageSection>
        <Container>
          <HeroComponent
            title="Build, Run, Maintain & Collaborate"
            description="Get your data workflows to production in minutes."
            ctaButtons={<CtaButtons center={false} />}
            imageSrc="/images/dataPreparationBanner.svg"
            layout="row"
            reverse={false}
            imagePosition="right"
            bgColor=""
            textColor="text-gray-900"
          />
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={2}>Overview</HeadingComponent>
          <Paragraph size="lg">
            Build data pipelines with Xceed Visual Designer — a no code way to
            build and manage data pipelines and create golden datasets for ML
            and Analytics use cases. Xceed Visual Designers helps users without
            minimal or no technical/coding skills become data engineers by
            simplifying building, managing and deploying data pipelines. Stop
            worrying about deployment, language syntax, scalability &
            productionizing. We have it all covered. Extract value off the raw
            data in datalake and create high quality data models for your use
            cases. Share data models instantly effortlessly between ML and
            Analytics team.
          </Paragraph>
        </Container>
      </PageSection>

      <PageSectionContent
        preHeading="BETTER ROI ON YOUR BIG DATA PROJECTS"
        title="Empower everyone with the power of low code data transformation"
        listItems={[
          "No need for coding and deployment skills. Any one can be a data engineer.",
          "No hassle of maintaining code, revisions etc",
          "Create high quality data models for your valuable use cases effortlessly and efficiently.",
          "Publish and deploy instantly for Analytics or ML use cases.",
        ]}
        contentType="list"
        layout="row"
        imageSrc="/images/dataPreparationVisual1.svg"
        imageAlt="cynepia data connectors"
        textWidth="w-1/2"
        mediaWidth="w-1/2"
        textColor="text-gray-900"
      />

      <PageSectionContent
        preHeading="No code / Low code platform"
        title="Build Data Pipelines"
        listItems={[
          "Create Resuable Multistep Data Ingenstion Pipeline And Store the Transformed Data Back Into Datalake.",
          "Virtualize and Store it directly into datalake",
          "Make the transformed data available instantly via Enterprise Data Catalog for Consumption",
        ]}
        contentType="list"
        layout="row"
        reverse={true}
        imageSrc="/images/dataPreparationVisual2.svg"
        imageAlt="cynepia data connectors"
        textWidth="w-1/2"
        mediaWidth="w-1/2"
        textColor="text-gray-900"
      />

      <PageSectionContent
        preHeading="Collaborate Between Data Science & BI Teams"
        title="Govern, Share and Collaborate"
        listItems={[
          "Share your data models across all the data teams who need access to your model instantly.",
          "Ensure single source of truth across use cases",
          "Never start from scratch. Reuse and Build newer models on top of existing models.",
        ]}
        contentType="list"
        layout="row"
        imageSrc="/images/dataPreparationVisual3.svg"
        imageAlt="cynepia data connectors"
        textWidth="w-1/2"
        mediaWidth="w-1/2"
        textColor="text-gray-900"
      />

      <PageSectionContent
        preHeading="Automate To Accelarate"
        title="Automate Data Engineering Tasks"
        listItems={[
          "Schedule data pipelines with Xceed Workflow Jobs for end-to-end production-ready pipelines.",
          "Accelarate time to value for your data projects.",
          "Trigger downstream dashboard and model refresh on successful job completion.",
        ]}
        contentType="list"
        layout="row"
        reverse={true}
        imageSrc="/images/dataPreparationVisual4.svg"
        imageAlt="cynepia data connectors"
        textWidth="w-1/2"
        mediaWidth="w-1/2"
        textColor="text-gray-900"
      />

      <PageSection>
        <Container>
          <HeadingComponent level={3} className="mb-12">
            Technical Capabilities
          </HeadingComponent>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {getListingData().map((feature) => {
              return <ListOfCardsWithIcons {...feature} />;
            })}
          </div>
        </Container>
      </PageSection>

      <ContactUsSection />
    </div>
  );
};

export default DataEngineeringAndMl;
