import CtaButtons from "@/components/ctaButtons/ctaButtons";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import HeadingComponent from "@/components/ui/HeadingComponent";

interface ContactUsProps {
  size?: string; // Add size if you intend to use it
  bg?: string; // Add size if you intend to use it
}


const contactUsSection = (props: ContactUsProps) => {
  return (
    <PageSection>
      <Container>
        <div className="bg-gray-100 rounded-xl p-10">
          <div className="flex g-5 flex-wrap w-full">
            <div className="lg:w-1/3 md:w-1/2">
              <HeadingComponent level={4}>
                Still confused ? Let us help you.
              </HeadingComponent>
              <div className="mb-5 text-gray-500">
                Fill out your details and our sales representive will reach you
                and help you with your queries.
              </div>
              <div className="cta-wrapper d-flex gap-3 align-items-center">
                <CtaButtons size={props.size} />
              </div>
            </div>
            <div className="lg:w-2/3 md:1/2 align-center ">
              <div className="img-wrapper flex items-center justify-center">
                <img
                  src="/images/contactUsEmailVector.svg"
                  alt="cynepia contact us"
                  className="w-[30%]"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </PageSection>
  );
};

export default contactUsSection;
