import { Link } from "react-router-dom";

import SelfServiceBISvg from "./featureSetIcons/selfServiceBi.svg?react";
import ScalableArchitecture from "./featureSetIcons/scalableArchitecture.svg?react";
import Flexible from "./featureSetIcons/flexible.svg?react";
import SecureDatabase from "./featureSetIcons/secureDatabase.svg?react";
import StayConnected from "./featureSetIcons/stayConnected.svg?react";
import BroaderConnectivity from "./featureSetIcons/broaderConnectivity.svg?react";
import Governance from "./featureSetIcons/governance.svg?react";
import ApiSupport from "./featureSetIcons/apiSupport.svg?react";
import GlobalSupport from "./featureSetIcons/globalSupport.svg?react";

import PageSection from "../../ui/PageSection";
import Container from "../../ui/Container";
import PreHeading from "@/components/ui/PreHeading";
import Button from "@/components/ui/Button";
import { cn } from "@/lib/utils";

function getContents() {
  return [
    {
      imgSrc: "images/icons/RobotIcon.svg",
      title: "Data Agent",
      description: "Truly self-serve data-agent built for business teams",
      width: 80,
      action: {
        link: "/products/ai-agents",
      },
    },
    {
      ImgIcon: SelfServiceBISvg,
      title: "Self-Service BI",
      description:
        "Quick & Easy to analyze data and build personalized storyboards.",
      width: 60,
      action: {
        link: "/products/self-service-bi",
      },
    },
    {
      ImgIcon: ScalableArchitecture,
      title: "Scalable Architecture",
      description:
        "Micro-services coupled with in-memory analytics engine ensures scalability.",
      width: 60,
      action: {
        link: "/products/architecture",
      },
    },
    {
      ImgIcon: Flexible,
      title: "Flexible Engine",
      description:
        "Flexible Choice of Analytics Engine support your data size needs.",
      width: 55,
      action: {
        link: "/products/architecture",
      },
    },
    {
      ImgIcon: SecureDatabase,
      title: "Single Tenent",
      description:
        "Stay in control of your data ownership, privacy and security requirements.",
      width: 50,
    },
    {
      ImgIcon: StayConnected,
      title: "Stay Connected",
      description: "Access your data and insights even while on the move",
      width: 60,
    },
    {
      ImgIcon: BroaderConnectivity,
      title: "Broader Connectivity",
      description:
        "Supports both streaming and batch data sources including Twitter",
      width: 60,
      action: {
        link: "/products/data-connectors",
      },
    },
    {
      ImgIcon: Governance,
      title: "Data Governance",
      description:
        "Ensure secure user access and privileges based on user roles.",
      width: 60,
      action: {
        link: "/products/architecture",
      },
    },
    {
      ImgIcon: ApiSupport,
      title: "API Support",
      description: "Build and integrate with existing enterprise applications.",
      width: 60,
    },
    {
      ImgIcon: GlobalSupport,
      title: "Global Support",
      description: "Build and integrate with existing enterprise applications.",
      width: 60,
    },
  ];
}

const featureSet = () => {
  return (
    <PageSection>
      <Container>
        <PreHeading>Key Capabilities</PreHeading>
        <div className="text-4xl font-semibold text-gray-700 lg:w-1/2 mb-5">
          Empower Citizen Data Engineers & Scientists to enable powerful
          insights.
        </div>
        <div className="flex items-stretch flex-wrap">
          {getContents().map((item, i) => {
            const { ImgIcon, imgSrc } = item;
            return (
              <div
                className={cn(
                  "w-full lg:w-1/3 md:w-1/2 p-5 border-gray-200 border"
                )}
                key={i}
              >
                <div className="content h-full flex flex-col gap-5">
                  <div className="img-wrapper flex items-center h-[50px]">
                    {ImgIcon ? (
                      <ImgIcon style={{ fill: "#fff", width: item.width }} />
                    ) : (
                      ""
                    )}
                    {imgSrc ? (
                      <img
                        style={{ width: item.width }}
                        src={imgSrc}
                        alt={item.title}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="card-body d-flex flex-direction-column">
                    <div className="title font-semibold text-gray-700 mb-1">
                      {item.title}
                    </div>
                    <div className="description mb-3">{item.description}</div>
                    {item.action ? (
                      <Link to={item.action.link} className="">
                        <Button size="md">
                          {item.title ? item.title : "Learn More"}
                        </Button>
                        <button className="button primary small arrow-link"></button>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </PageSection>
  );
};

export default featureSet;
