import React, { useState } from "react";
import InputWithButton from "../inputWithButton/inputWithButton.tsx";
import { CheckCircleOutline } from "@mui/icons-material";
import { Link } from "react-router-dom";

const SubscribeSection = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [requestOnGoing, setRequestOnGoing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setRequestOnGoing(true);

    const bodyParams = { email: e.target.elements.email.value };
    const url = window.__SubscribeNewsletter__;

    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(bodyParams),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      if (response.status === 200) {
        setFormSubmitted(true);
      } else {
        alert("Subscribe Failed. Please try again.");
        console.error(result);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setRequestOnGoing(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-6 py-3">
      <div className="md:w-1/2">
        <h2 className="text-blue-600 text-2xl font-bold mb-2">
          Get insights delivered
        </h2>
        <h2 className="text-2xl font-semibold">Right in your inbox</h2>
      </div>
      <div className="md:w-2/5">
        {!formSubmitted ? (
          <div className="ms-auto">
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="flex items-center space-x-2">
                <InputWithButton
                  inputPlaceholder="Enter your work email"
                  inputType="email"
                  required={true}
                  inputName="email"
                  buttonType="submit"
                  disabled={requestOnGoing}
                />
              </div>
            </form>
            <small className="text-gray-600 text-sm mt-2 block">
              By submitting this form you agree to our{" "}
              <Link to="/privacy" className="text-blue-500">
                Privacy Policy
              </Link>{" "}
              and our{" "}
              <Link to="/terms" className="text-blue-500">
                Terms of Use
              </Link>
              .
            </small>
          </div>
        ) : (
          <div className="bg-green-600 text-white p-4 rounded-lg flex items-center gap-3">
            <CheckCircleOutline className="text-white" />
            <span>Thank you for subscribing to our newsletter.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscribeSection;
