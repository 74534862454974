import AnimatedArrowIconLink from "@/components/animatedArrowIconLink/animatedArrowIconLink";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import PreHeading from "@/components/ui/PreHeading";
import Paragraph from "@/components/ui/Paragraph";

const getData = () => [
  {
    link: window.__BlogUrl__ + "introducing_xceed_ai_assistant",
    imgUrl: "images/ai_assistant.jpg",
    title: "Introducing Xceed AI Assistant",
    description: `In the era of Language Models and Generative AI
    applications, Xceed AI Assistant aims to offer a
    comprehensive AI Assistant across all the data tasks and
    functionalities within Xceed Analytics.`,
    tags: ["AI Assistant"],
  },
  {
    link: window.__BlogUrl__ + "introducing_no_code_data_quality_monitoring",
    imgUrl: "images/customerRiskManagement.jpg",
    title: "Introducing Xceed Data Quality Monitor",
    description: `In the era of Language Models and Advanced Artificial Intelligence Applications, need for reliable and accurate data has never been more important than now.`,
    tags: ["Data Observability"],
  },
  {
    link:
      window.__BlogUrl__ +
      "is_end_to_end_data_and_ai_platform_answer_to_success_of_datascience_projects",
    imgUrl: "images/dataStratergy.jpg",
    title:
      "Is Unified Data & AI Platform answer to improving success of Data Science projects?",
    description: `More and more enterprises are embracing data science as a function and a capability.`,
    tags: ["Analytics"],
  },
];

const onBlogCardClick = (link) => {
  window.open(link, "_blank");
};

const FeaturedBlogSection = () => {
  return (
    <div className="border-t border-gray-200">
      <PageSection>
        <Container>
          <PreHeading>Featured Articles</PreHeading>
          <div className="flex justify-between items-center mb-8">
            <h3 className="text-2xl font-bold text-gray-800">From Our Blog</h3>
            <AnimatedArrowIconLink
              clickCb={() => onBlogCardClick(window.__BlogUrl__)}
              content="Read More Articles"
              classNames="text-blue-500 hover:underline"
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {getData().map((item, i) => (
              <div
                key={i}
                className="w-full cursor-pointer border border-gray-200 rounded-xl overflow-hidden"
                onClick={() => onBlogCardClick(item.link)}
              >
                <div className="w-full h-50 sm:h-40 md:h-50 lg:h-45">
                  <img
                    src={item.imgUrl}
                    alt={item.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-4 bg-white">
                  <div className="flex items-center flex-wrap mb-3 gap-2">
                    {item.tags.map((tag, index) => (
                      <span
                        key={index}
                        className="rounded-full bg-blue-100 text-blue-500 px-2 py-1 font-medium text-xs inline-flex"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                  <h4 className="font-semibold mb-2">{item.title}</h4>
                  <Paragraph className="mb-5">{item.description}</Paragraph>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </PageSection>
    </div>
  );
};

export default FeaturedBlogSection;
