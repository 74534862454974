import React from "react";
import { Link } from "react-router-dom";
import SocialIcons from "../../../socialIcons/socialIcons";
import AnimatedArrowIconLink from "../../../animatedArrowIconLink/animatedArrowIconLink";
import HeadingComponent from "@/components/ui/HeadingComponent";

const CompanyDropdown = () => {
  return (
    <div className="relative ml-auto nav-dropdown">
      {/* Using a flex container instead of Bootstrap's row */}
      <div className="flex">
        {/* Left Column */}
        <div className="bg-white w-full md:w-5/12 relative p-4">
          <h4 className="text-lg font-bold mb-4">Who we are?</h4>
          <div className="list-wrapper">
            <ul className="list-none">
              <li className="mb-2">
                <Link
                  to="/about-us"
                  className="uppercase text-blue-600 hover:underline"
                >
                  About Us
                </Link>
              </li>
              <li
                className="cursor-pointer mb-2 text-blue-600 hover:underline"
                onClick={() => window.open(window.__CareersUrl__, "_blank")}
              >
                Careers
              </li>
              <li className="mb-2">
                <Link to="/partners" className="text-blue-600 hover:underline">
                  Partners
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/contact-us"
                  className="text-blue-600 hover:underline"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          {/* Social Icons positioned absolutely at the bottom right */}
          <div className="absolute bottom-5 right-0 social-icon-wrapper">
            <SocialIcons />
          </div>
        </div>

        {/* Right Column */}
        <div className="accent-bg-2 w-full md:w-7/12">
          <div className="p-4">
            <HeadingComponent className="mb-4 text-xl font-semibold">
              Cynepia Technologies
            </HeadingComponent>
            <p className="text-base">
              <strong>
                <span className="text-highlight-dark-background">
                  Our Stratergy
                </span>
              </strong>{" "}
              is to provide cutting edge software systems to help enterprises
              organize, connect, and make sense of their data, stay connected
              with their insights, make faster real-time decisions, and
              ultimately grow your business.
            </p>
            <AnimatedArrowIconLink to="/about-us" content="Read More" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDropdown;
