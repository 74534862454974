import { JSX, useState } from "react";
import ContentTabs from "@/components/ContentTabs/ContentTabs";
import CreateAgentFlow from "./CreateAgentFlow";
import CustomAgentsSection from "./CustomAgentsSection";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";

interface SectionTabType {
  id: number;
  title: string;
  Component: JSX.Element;
}

const ContentTabbedSection = () => {
  const sectionTabs: SectionTabType[] = [
    {
      id: 1,
      title: "Create your own agents",
      Component: <CreateAgentFlow />,
    },
    {
      id: 2,
      title: "Pre-built agents",
      Component: <CustomAgentsSection />,
    },
  ];

  const [ActiveSection, setActiveSection] = useState(sectionTabs[0]);

  const onTabClick = (section: SectionTabType) => {
    setActiveSection(section);
  };

  return (
    <>
      <PageSection>
        <Container>
          <div className="contentTabsSection flex justify-center flex-col">
            <div className="mb-10 flex justify-center">
              <ContentTabs
                sections={sectionTabs}
                onTabClick={onTabClick}
                size={"xl"}
              />
            </div>
            <div className="">{ActiveSection.Component}</div>
          </div>
        </Container>
      </PageSection>
    </>
  );
};

export default ContentTabbedSection;
