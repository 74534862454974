import React from "react";
import SocialIcons from "../../../socialIcons/socialIcons";
import { ArrowRight } from "@mui/icons-material";
import AnimatedArrowIconLink from "../../../animatedArrowIconLink/animatedArrowIconLink";
import HeadingComponent from "@/components/ui/HeadingComponent";

const CompanyDropdown = () => {
  return (
    <div id="nav-learn-dropdown" className="relative w-1/2">
      <div className="w-full h-full">
        <div className="flex h-full gap-3">
          {/* Left Column */}
          <div className="w-full lg:w-1/2 bg-white">
            <div className="p-4 h-full relative">
              <h4 className="text-xl font-bold mb-4">Learn</h4>
              <ul className="list-none">
                <li
                  className="mb-3 cursor-pointer"
                  onClick={() =>
                    window.open(window.__DocumentationUrl__, "_blank")
                  }
                >
                  <AnimatedArrowIconLink content="Documentation" />
                </li>
                <li
                  className="mb-2 cursor-pointer"
                  onClick={() => window.open(window.__BlogUrl__, "_blank")}
                >
                  <AnimatedArrowIconLink content="Blogs" />
                </li>
              </ul>
              <div className="absolute bottom-5 right-0">
                <SocialIcons />
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div
            className="w-full lg:w-1/2 bg-cover bg-center"
            style={{ backgroundImage: "url(images/risk_assesment.jpg)" }}
          >
            <div className="p-4">
              <div className="mb-4">
                <span className="text-sm font-bold rounded bg-white px-3 py-2 opacity-90">
                  FEATURED
                </span>
              </div>
              <div className="bg-white p-4 rounded opacity-90">
                <HeadingComponent className="text-lg font-bold mb-2">
                  Getting Customer Risk Assessment Modeling Right for
                  Microfinance Lenders
                </HeadingComponent>
                <p className="text-sm mb-4">
                  A Good Customer Risk Assessment Model is to keep track of
                  borrower risk during the tenure of the loan, post disbursement
                  by monitoring risk profile change of a borrower periodically.
                </p>
                <span
                  className="cursor-pointer flex items-center space-x-1"
                  onClick={() =>
                    window.open(
                      window.__BlogUrl__ +
                        "getting_credit_risk_assessment_model_right",
                      "_blank"
                    )
                  }
                >
                  <span>Read More</span>
                  <ArrowRight />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDropdown;
