import { cn } from "../../lib/utils";
import React, { useState } from "react";
import "./ContentTabs.scss";

const ContentTabs = ({ sections, onTabClick, size = "lg" }) => {
  const [selected, setSelected] = useState(sections[0]);

  const tabClick = (section) => {
    setSelected(section);
    onTabClick(section);
  };

  return (
    <div className="inline-flex items-center gap-3 " id="ComponentContentTabs">
      {sections.map((section) => {
        return (
          <div
            className={cn(
              "flex flex-col justify-center gap-3 contentTab px-5 py-3 rounded-3 cursor-pointer rounded-lg border border-gray-100",
              selected.title === section.title
                ? "shadow-sm text-white"
                : "bg-gray-50",
              "text-" + size
            )}
            key={section.title}
            onClick={() => tabClick(section)}
          >
            {section.icon && (
              <span className="inline section-icon mx-auto">
                {section.icon}
              </span>
            )}
            <span className="contentTitle m-0">{section.title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ContentTabs;
