import ContactUsSection from "@/components/contactUsSection/contactUsSection";
import CtaButtons from "@/components/ctaButtons/ctaButtons";

import HeadingComponent from "@/components/ui/HeadingComponent";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import HeroComponent from "@/components/ui/HeroComponent";
import { FileCog, FolderTree, SquareLibrary, View } from "lucide-react";
import ListOfCardsWithIcons from "@/components/ui/ListOfCardsWithIcons";
import PageSectionContent from "@/components/ui/PageSectionContent";
import Paragraph from "@/components/ui/Paragraph";

const getListingData = () => {
  return [
    {
      title: "Integrated Lake for Unstructured Files",
      src: "dashboard.svg",
      iconComponent: <FolderTree size={20} />,
      desc: "Create, organize and share powerful executive dashboards by role or departments in minutes.",
    },
    {
      title: "Support for common file formats",
      src: "pdfReports.svg",
      desc: "Supports common file formats such as DOCX, PDF, PPTX, URL and more",
      iconComponent: <FileCog size={20} />,
    },
    {
      title: "Integrated Viewer for common file formats",
      src: "filter.svg",
      desc: "Filter and Drilldown at various levels and hierarchy.",
      iconComponent: <View size={20} />,
    },
    {
      title: "Pre-Integrated with Vector Store & Knowledge Parser",
      src: "desktopVisualization.svg",
      desc: "Transform complex documents into LLM ready clean data for LLM applications",
      iconComponent: <SquareLibrary size={20} />,
    },
  ];
};

const Files = () => {
  return (
    <div id="selfServiceBIPage">
      <PageSection>
        <Container>
          <HeroComponent
            title="Transition Into Data Driven Decision Future"
            description="Create dashboards and reports charts instantly. Stop using multiple dashboarding tools."
            ctaButtons={<CtaButtons />}
            imageSrc="/images/fileHeroBanner.svg"
            imagePosition="right"
            layout="row"
            bgColor="bg-white"
          />
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={3}>
            Don’t leave any story behind
          </HeadingComponent>
          <Paragraph className="text-lg">
            Our powerful ad-hoc story designer lets users design compelling data
            stories using charts and maps that help decision-makers quickly
            grasp the generated insights. An interactive dashboard provides a
            variety of options for designing dynamic, mobile-friendly
            dashboards. Any user with limited technical skills can author, view,
            and engage with these data stories easily and instantly.
          </Paragraph>
        </Container>
      </PageSection>

      <PageSectionContent
        preHeading="Create LLM Ready Knowledge Base"
        contentType="list"
        title="Create LLM ready data instantly"
        listItems={[
          "Removes friction since all files is in one place.",
          "Use the knowledge base to create LLM applications instantly",
        ]}
        imageSrc="/images/allFileSupportFilePage.svg"
        imageAlt="cynepia data connectors"
        bgClass=""
        gridClass=""
      />

      <PageSectionContent
        title="Govern, Share and Collaborate"
        preHeading="All in one place"
        contentType="list"
        listItems={[
          "Ensure that everyone has access to correct files.",
          "Distributed Governance ensures instant access of files to users who need it.",
          "Notify users on events.",
        ]}
        reverse={true}
        imageSrc="/images/dataPreparationVisual3.svg"
        imageAlt="cynepia data connectors"
      />
      <PageSection>
        <Container>
          <HeadingComponent level={3} className="mb-12">
            Technical Capabilities
          </HeadingComponent>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {getListingData().map((feature) => {
              return <ListOfCardsWithIcons {...feature} />;
            })}
          </div>
        </Container>
      </PageSection>

      <ContactUsSection />
    </div>
  );
};

export default Files;
