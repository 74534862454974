import ContactUsSection from "@/components/contactUsSection/contactUsSection";
import CtaButtons from "@/components/ctaButtons/ctaButtons";
import ListComponent from "@/components/listComponent/listComponent";
import PageSection from "@/components/ui/PageSection";
import Container from "@/components/ui/Container";
import PreHeading from "@/components/ui/PreHeading";
import HeroComponent from "@/components/ui/HeroComponent";
import HeadingComponent from "@/components/ui/HeadingComponent";
import ListOfCardsWithIcons from "@/components/ui/ListOfCardsWithIcons";
import {
  CircleDollarSign,
  Eye,
  Filter,
  FolderLock,
  TextQuote,
  TextSearch,
} from "lucide-react";

const getListingData = () => {
  return [
    {
      iconComponent: <Eye />,
      title: "360 Degree Visibility",
      desc: "Discover all your enterprise Data & AI assets from a single page.",
    },
    {
      title: "Automatic Vector Indexing",
      desc: "Automatically generate embeddings using popular open-source embedding models and vector databases.",
      iconComponent: <TextQuote />,
    },
    {
      title: "Filter by Asset Type",
      desc: "Only fetch assets that match a given asset type.",
      iconComponent: <Filter />,
    },
    {
      title: "Vector & Keyword Search",
      desc: "A combination of semantic search and keyword search to improve search relevance.",
      iconComponent: <TextSearch />,
    },
    {
      title: "Governed & Secure",
      desc: "Granular Governance ensures everyone has access to what is required for their specific role.",
      iconComponent: <FolderLock />,
    },
    {
      title: "Save Cost",
      desc: "Hosted open-source embedding model, vector database ensures no additional provider cost while users search.",
      iconComponent: <CircleDollarSign />,
    },
  ];
};

const SmartSearch = () => {
  return (
    <div id="selfServiceBIPage">
      <PageSection>
        <Container>
          <HeroComponent
            title="Smart AI Search & Discovery across all your Data & AI Asset"
            description="Improved User Experience via Smart AI Search helps data consumers easily access relevant data assets."
            ctaButtons={<CtaButtons />}
            imageSrc="/images/searchBanner.svg"
            imagePosition="right"
          />
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={3}>
            Explore all entire data assets in minutes, Find relevant asset you
            need quickly, save time searching
          </HeadingComponent>
          <p className="text-lg">
            Be it data connectors, datasets in your data catalog, model
            registry, jobs, SQL models, Python models, or no-code workflows, we
            have all your data landscape covered. Discover all your data from a
            single view.
          </p>
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <div className="grid md:grid-cols-2 gap-5">
            <div>
              <PreHeading>SEARCH WHAT YOU NEED, WHEN YOU NEED</PreHeading>
              <HeadingComponent level={3}>
                One place to find all your data assets
              </HeadingComponent>
              <ListComponent
                data={[
                  "Smart Search using Keyword Matching & Semantic Understanding via vector search.",
                  "360-degree visibility of all your enterprise data and AI assets from a single page.",
                  "Governed & Secure search ensures users can only discover the assets they have access to.",
                ]}
              />
            </div>
            <div className="flex justify-center bg-gray-100 rounded-lg">
              <div className="bg-[url(/images/gridbg.svg)] bg-contain p-5 flex justify-center items-center">
                <img
                  src="/images/searchFeature.svg"
                  className="w-[80%]"
                  alt="cynepia data connectors"
                />
              </div>
            </div>
          </div>
        </Container>
      </PageSection>

      <PageSection>
        <Container>
          <HeadingComponent level={3}>Technical Capabilities</HeadingComponent>
          <div className="grid md:grid-cols-3 gap-6">
            {getListingData().map((item, index) => (
              <ListOfCardsWithIcons {...item} key={index} />
            ))}
          </div>
        </Container>
      </PageSection>

      <ContactUsSection />
    </div>
  );
};

export default SmartSearch;
