import React from "react";
import { cn } from "@/lib/utils";
import { LucideIcon } from "lucide-react";

type InputSize = "sm" | "md" | "lg";
type InputState = "default" | "error" | "success" | "disabled";

type InputProps = {
  label?: string;
  placeholder?: string;
  size?: InputSize;
  state?: InputState;
  leftIcon?: LucideIcon;
  rightIcon?: LucideIcon;
  className?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  focused?: string;
  border?: string;
  name?: string;
};

const sizeClasses: Record<InputSize, string> = {
  sm: "px-2 py-1 text-sm",
  md: "px-3 py-2 text-base",
  lg: "px-4 py-3 text-lg",
};

const stateClasses: Record<InputState, string> = {
  default: "border-gray-300 focus:ring-blue-500",
  error: "border-red-500 focus:ring-red-500",
  success: "border-green-500 focus:ring-green-500",
  disabled: "border-gray-300 bg-gray-100 cursor-not-allowed text-gray-500",
};

const InputText: React.FC<InputProps> = ({
  label,
  placeholder,
  size = "md",
  state = "default",
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  focused = "focus:ring-2 focus:ring-blue-500",
  className = "",
  value,
  onChange,
  name,
  border = "border",
  required = false,
}) => {
  return (
    <div className="flex flex-col space-y-1 w-full">
      {label && <label className="text-sm font-medium">{label}</label>}
      <div className={cn("relative flex items-center", className)}>
        {LeftIcon && (
          <LeftIcon className="absolute left-3 h-5 w-5 text-gray-500" />
        )}
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={state === "disabled"}
          required={required}
          className={cn(
            "w-full rounded-md outline-none transition-all",
            sizeClasses[size],
            focused,
            border,
            stateClasses[state],
            LeftIcon ? "pl-10" : "pl-3",
            RightIcon ? "pr-10" : "pr-3"
          )}
        />
        {RightIcon && (
          <RightIcon className="absolute right-3 h-5 w-5 text-gray-500" />
        )}
      </div>
    </div>
  );
};

export default InputText;
