import { Link } from "react-router-dom";
import Paragraph from "@/components/ui/Paragraph.tsx";
import { cn } from "@/lib/utils";

export interface LinkItemProps {
  title: string;
  description?: string;
  iconComponent: React.ReactNode;
  to: string;
}

const LinkItem: React.FC<LinkItemProps> = (obj) => {
  return (
    <Link to={obj.to}>
      <div
        className={cn(
          "flex gap-3 rounded-lg hover:bg-gray-50 p-3",
          obj.description ? "items-start" : "items-center"
        )}
      >
        <div className="bg-blue-100 p-2 flex items-center rounded-md text-sm">
          {obj.iconComponent}
        </div>
        <div className="flex flex-col gap-1">
          <div className="font-semibold text-gray-800 text-sm">{obj.title}</div>
          {obj.description && <Paragraph>{obj.description}</Paragraph>}
        </div>
      </div>
    </Link>
  );
};

const LinkItemList: React.FC<{
  items: LinkItemProps[];
  title: string;
  cols?: number;
}> = ({ items, title, cols }) => {
  return (
    <>
      {title && (
        <div className="text-gray-500 font-semibold mb-5 text-sm">{title}</div>
      )}
      <div
        className={cn(
          "grid gap-3",
          `grid-cols-${cols != undefined ? cols : 1}`
        )}
      >
        {items.map((item, i) => {
          return <LinkItem {...item} key={i} />;
        })}
      </div>
    </>
  );
};

export { LinkItem, LinkItemList };
