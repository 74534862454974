import React, { ButtonHTMLAttributes } from "react";
import { Loader2 } from "lucide-react";
import { cn } from "../../lib/utils";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: "sm" | "md" | "lg";
  variant?: "solid" | "outline" | "ghost" | "link" | "solidBlueInverted";
  leftIcon?: React.ElementType;
  rightIcon?: React.ElementType;
  isLoading?: boolean;
  fullWidth?: boolean;
  rounded?: string;
};

const buttonSizes = {
  sm: "px-3 py-1.5 text-sm",
  md: "px-4 py-2 text-base",
  lg: "px-5 py-2.5 text-lg",
};

const buttonVariants = {
  solid: "bg-blue-600 text-white hover:bg-blue-700",
  outline: "border border-gray-300 text-gray-800 hover:bg-gray-100",
  ghost: "text-gray-800 hover:bg-gray-100",
  link: "text-blue-600 underline-offset-4 hover:underline",
  solidBlueInverted: "bg-blue-400 hover:bg-blue-300 text-white",
};

const Button: React.FC<ButtonProps> = ({
  children,
  size = "md",
  variant = "solid",
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  isLoading = false,
  disabled = false,
  fullWidth = false,
  className = "",
  rounded = "md",
  ...props
}) => {
  return (
    <button
      className={cn(
        "flex items-center justify-center gap-2 rounded-lg font-medium transition-all cursor-pointer",
        buttonSizes[size],
        buttonVariants[variant],
        disabled == true ? "opacity-50 cursor-not-allowed" : "",
        fullWidth == true ? "w-full" : "",
        "rounded-" + rounded,
        className
      )}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading && <Loader2 className="animate-spin w-4 h-4" />}
      {LeftIcon && <LeftIcon className="w-5 h-5" />}
      {children}
      {RightIcon && <RightIcon className="w-5 h-5" />}
    </button>
  );
};

export default Button;
