export function cn(...classNames: (string| null | undefined | Record<string, boolean>)[]) {
  return classNames
    .flat()
    .filter(Boolean)
    .map((cls) => {
      if (cls && typeof cls === "object") {
        return Object.keys(cls)
          .filter((key) => cls[key])
          .join(" ");
      }
      return cls || "";
    })
    .join(" ")
    .trim();
}

export function getCurrentDateTime() {
  const now = new Date();
  return now.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
}
