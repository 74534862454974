import ContactUsSection from "@/components/contactUsSection/contactUsSection";
import CtaButtons from "@/components/ctaButtons/ctaButtons";

import ContentTabbedSection from "./ContentTabbedSection";

import AgentUseCasesSection from "@/components/pages/home/AgentUseCasesSection";
import Container from "@/components/ui/Container";
import HeroComponent from "@/components/ui/HeroComponent";
import PageSection from "@/components/ui/PageSection";

import PageSectionContent from "@/components/ui/PageSectionContent";

const AiAgentView = () => {
  return (
    <>
      <div className="page" id="dataEngineeringAndMlPage">
        <PageSection>
          <Container>
            <HeroComponent
              title="Build Evaluate & Deploy AI Agents"
              description="Automate tasks using no-code Agent Builder,
                evaluate, deploy and monitor all from one unified view"
              announcement="New Features Released!"
              ctaButtons={<CtaButtons />}
              imageSrc="/images/aiOverview.svg"
              layout="row"
            />
          </Container>
        </PageSection>
        <PageSectionContent
          title="Overview"
          description={
            <>
              Xceed AI agents helps you build, evaluate, and deploy AI agents
              and converse with the agents through a familiar chat interface.
              Creating dedicated agent force for your enterprise was never as
              easy.
              <br></br>
              <br></br>
              Pre-built agents such as Jane enable business teams can
              effortlessly create a story/dashboard without any knowledge of
              SQL. Data analyst teams support and monitor responses and
              influence by constantly improving its knowledge base. With
              additional custom knowledge, Jane becomes smarter and reliable.
            </>
          }
          contentType="paragraph"
          imageSrc="/images/agentFlow.svg"
          imageAlt="Xceed AI agents"
          layout="column"
          bgClass=""
          gridClass=""
        ></PageSectionContent>

        <PageSectionContent
          preHeading="Purpose built AI Agents"
          title="Build AI Agents for your enterprise"
          description={`Create custom agents for your specific usecase using Xceed
          Agent Builder and Xceed Intelligence SDK. Supports creating
          complex workflows that can chain multiple agentic tasks in a
          single agentic workflow.`}
          imageSrc="/images/AgentCreateBordered.svg"
          imageAlt="Data agents for all roles"
          bgClass=""
          layout="column"
          gridClass=""
        ></PageSectionContent>

        <ContentTabbedSection />
        <AgentUseCasesSection />
        <ContactUsSection />
      </div>
    </>
  );
};

export default AiAgentView;
