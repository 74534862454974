import React from "react";
import HeadingComponent from "./HeadingComponent";
import { cn } from "@/lib/utils";

interface HeroProps {
  title: string;
  description?: string;
  ctaButtons?: React.ReactNode;
  announcement?: string;
  imageSrc: string;
  layout?: "row" | "column";
  reverse?: boolean;
  imagePosition?: "left" | "right" | "top" | "bottom";
  bgColor?: string;
  textColor?: string;
}

const HeroComponent: React.FC<HeroProps> = ({
  title,
  description,
  ctaButtons,
  announcement,
  imageSrc,
  layout = "row",
  reverse = false,
  imagePosition = "right",
  bgColor = "",
  textColor = "text-gray-900",
}) => {
  const isRow = layout === "row";
  const isReverse = isRow && reverse;

  return (
    <section className={`w-full py-8 ${bgColor} ${textColor}`}>
      <div
        className={`mx-auto flex ${
          isRow ? "lg:flex-row flex-col justify-center" : "flex-col"
        } items-center justify-between gap-8 md:justify-center`}
      >
        {/* Content Section */}
        <div
          className={cn(
            "flex-1",
            isReverse ? "order-last" : "",
            isRow ? "lg:text-left text-center" : "text-center"
          )}
        >
          {announcement && (
            <div className="mb-4 text-sm font-semibold uppercase tracking-wide text-blue-600">
              {announcement}
            </div>
          )}
          <HeadingComponent level={1} className={!isRow ? "text-center" : ""}>
            {title}
          </HeadingComponent>
          {description && (
            <p className="mt-4 text-lg text-gray-600">{description}</p>
          )}
          {ctaButtons && (
            <div
              className={cn(
                "mt-6",
                layout == "row" ? "flex lg:justify-start justify-center" : ""
              )}
            >
              {ctaButtons}
            </div>
          )}
        </div>

        {/* Image Section */}
        {(imagePosition === "right" ||
          (!isRow && imagePosition === "bottom")) && (
          <div className="flex-1 flex justify-center w-full">
            <img src={imageSrc} alt="Hero" className=" object-contain" />
          </div>
        )}
      </div>
    </section>
  );
};

export default HeroComponent;
